import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services';
import { DarkModeService } from 'angular-dark-mode';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  darkMode$ = this.darkModeService.darkMode$;

  constructor(public auth : AuthenticationService, public router : Router, private darkModeService: DarkModeService) { }

  ngOnInit() {
    console.log('sidebar');
  }

  onToggle(): void {
    this.darkModeService.toggle();
  }

  categories(){
    this.router.navigate(['admin', 'categories']);
  }

  dashboard(){
    this.router.navigate(['admin','dashboard']);
  }
  
  management(){
    this.router.navigate(['admin','management']);
  }

  groups(){
    this.router.navigate(['admin','groups']);
  }
  products(){
    this.router.navigate(['admin','details']);
  }
  sales(){
    this.router.navigate(['admin','sales']);
  }
  settings(){
    this.router.navigate(['admin','settings']);
  }

  geiger(){
    this.router.navigate(['admin','geiger']);
  }

  pos(){
    this.router.navigate(['pos']);
  }

  logOut(){
    this.auth.signOut();
  }

}
