// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'http://localhost:1337',
  cp: {
    api_key: '5579980505863a3f6aabd82.89189525',
    site_id: 659913,
    notify_url: 'https://YOUR_NOTIFY_URL',
    currency: 'CFA'
  },
  firebase : {
    apiKey: "AIzaSyC6pJSrQG-TZq_VEPxjNMwqY-6RAR5oI00",
    authDomain: "showcase-440e9.firebaseapp.com",
    databaseURL: "https://showcase-440e9-default-rtdb.firebaseio.com",
    projectId: "showcase-440e9",
    storageBucket: "showcase-440e9.appspot.com",
    messagingSenderId: "393424088211",
    appId: "1:393424088211:web:c3622e5f4b36db506cf166"
  }
};

/*export const environment = {
  production: false,
  api_url: 'http://localhost:1337',
  cp: {
    api_key: '5579980505863a3f6aabd82.89189525',
    site_id: 659913,
    notify_url: 'https://YOUR_NOTIFY_URL',
    currency: 'CFA'
  },
  firebase : {
  apiKey: "AIzaSyCYsHJNYOXr76j_OlwQrf6KUs-AUKblfhQ",
  authDomain: "showcasetest-67e9e.firebaseapp.com",
  projectId: "showcasetest-67e9e",
  storageBucket: "showcasetest-67e9e.appspot.com",
  messagingSenderId: "863574162156",
  appId: "1:863574162156:web:3335685865f760bdfac3c9",
  measurementId: "G-WJT6LT9BNZ"
  }
};*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
