import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';
import { Product, Sale } from 'src/app/core/models';
import { FirebaseService, NotifyService } from 'src/app/core/services';
import Swal from 'sweetalert2';
import * as mqtt from 'mqtt/dist/mqtt';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {

  sales : Sale[] = [];
  saleFilter: any = [];
  cartChk: boolean = false;
  temp:any;
  temp2:any;

  mqttServer = 'mqtt://broker.emqx.io';
  mqttPort = 1883;
  mqttTopic = 'bins';

  constructor(private firestore : FirebaseService, public notify : NotifyService, 
    private afs: AngularFirestore) { }

  ngOnInit() {
    this.fetch();
  }

  refresh(){
    this.fetch();
  }

  cartSee(){
    this.cartChk= true;
  }

  fetch() {
    this.afs.collection('sales').valueChanges().subscribe((res : any) => {
      this.sales = res;
      //this.temp2 = this.carts(this.sales[0].carts);
      //console.log(this.temp2[0].quantity);
      //console.log(this.sales[0].carts.quantity);
    });
  }

  show(sale: Sale[]){
    
  }

  carts(cart: any){
    return JSON.parse(cart);
  }

  cartFilter(data: any){
    this.temp = this.carts(data);
    this.saleFilter=[];
    this.temp.forEach( x=> this.saleFilter.push(' '+x.name+' '+'x'+' '+x.quantity+' '));
    
    return this.saleFilter;
  }

  formatSaleFilter(saleFilter: string[]): any {
    return { "cell_count": saleFilter }
    //return JSON.stringify({ "cell_count": saleFilter }, null, 2)
  }

  mqttSender(data: any){
    this.temp = this.carts(data);
    this.saleFilter=[];
    this.temp.forEach( x=> this.saleFilter.push(''+x.name+''));
    return this.formatSaleFilter(this.saleFilter);
  }

  sendMqttMessage(data:any) {
    //const client = mqtt.connect(this.mqttServer, { port: this.mqttPort });
    const client = mqtt.connect('ws://broker.emqx.io:8083/mqtt');
  
    client.on('connect', () => {
      console.log('Connected to MQTT broker');

      // Convert the message to JSON
      const messageJson = JSON.stringify(data);
  
      // Publish the JSON message to the topic
      client.publish(this.mqttTopic, messageJson);
  
      console.log('Message sent:', messageJson);
  
      // Disconnect from the MQTT broker
      client.end();
    });
  
    client.on('error', (error) => {
      console.error('Error:', error);
    });
  }

  printFiltered(){
    console.log(this.sales)
  }

}
