<!--<p >Scan your product QR code</p>-->

<!--<p class="data">{{ action.data | async }}</p>-->

<p class="data" *ngIf="action.data | async">PRODUCT SCANNED!</p>


<div class="qr">
    <ngx-scanner-qrcode #action="scanner" [config]="config" (error)="onError($event)"></ngx-scanner-qrcode>
</div>

<div class="row">
  <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading">
    <img *ngIf="!action.isStart" (click)="handle(action, 'start')" src="https://img.icons8.com/ios/50/000000/no-camera--v2.png" width="30px"/>
    <img *ngIf="action.isStart" (click)="handle(action, 'stop')" src="https://img.icons8.com/ios/50/000000/no-camera--v1.png" width="30px"/>
  </button>
  <button (click)="qr(action); handle(action, 'stop');" label="Save Product" pButton type="button" class="p-button-sm"></button>
</div>
  
  