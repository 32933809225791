<div class="container" style="padding-top:40px;">
  
  <div class="col-md-8">
  <div class="card">

    <div class="row justify-content-center">

        <div class="col-md-12 text-center">
            <p class="text-white" style="padding-top:40px;"></p>
            <p class="card-title text-white">SHOWCASE POS SYSTEM</p>
        </div>

        <div class="card-body ai-icon">
          
          <a href="javascript:void(0);" class="btn my-2 btn-light" routerLink="/login" *ngIf="!isAuth">
            
            <p class="card-title row justify-content-center" style="padding-top:80px;">ADMIN LOGIN</p>
          </a>
          <a href="javascript:void(0);" class="btn my-2 btn-light" (click)="dashboard()" *ngIf="isAuth">
            <p class="card-title row justify-content-center" style="padding-top:80px;">MY DASHBOARD</p>
          </a>

          
          <a routerLink="/pos" class="btn my-2 btn-light">
            <p class="card-title row justify-content-center " style="padding-top:80px;">CUSTOMER POS</p>
          </a>

        </div>

    </div>

  </div>  
  </div>
  
</div>

    