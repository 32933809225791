<div class="col-md-12">
  
  <div class="card  cp">
    <div class="card-body">

      <div class="row">
        <div class="col-md-6 text-start">
          <!--<h2 class="mt-2">Showcases</h2>-->
          <p class="title text-black">Rack</p>
        </div>
        <div class="col-md-6 text-right">
          <!--<button (click)="refresh()" pButton type="button" icon="pi pi-refresh" class="p-button-sm p-button-link mr-2"></button>-->
          <button (click)="add()" label="Add new rack" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>
        </div>
      </div>

      <p-table dataKey="id" stateKey="categories" [value]="categories" styleClass="p-datatable-striped" [rows]="10"
    [paginator]="true" #yp1 lang="fr">

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Name 
        </th>
        <th class="text-right" pSortableColumn="created_at">Date Added
        </th>
        <th></th>
        
        
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-category>
      <tr>
        <td class="text-left"><span class="p-column-title">Name     :</span>{{ category.name }}</td>
        <td class="text-right"><span class="p-column-title">Date Added :</span>{{ category.created_at | date: "yyyy-MM-dd" }}</td>
        <td class="text-right">
          <button (click)="edit(category)" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>
          <button (click)="delete(category.id)" pButton type="button" icon="pi pi-trash" class="p-button-sm p-button-danger"></button>
        </td>
        
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" class="d-none">
      <tr>
        <td colspan="7">No categories found.</td>
      </tr>
    </ng-template>
  </p-table>

  </div>     
  </div>
</div>

  

  

<p-dialog *ngIf="display" [header]="category.name ? 'Category: ' + category.name : 'Add new rack'"  [resizable]="true" [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="display" [style]="{width: '50vw'}">
  <app-rack [display]="display" [category]="category" (call)="saver($event)"></app-rack>
</p-dialog>

<!--
          <th pSortableColumn="status">Status 
           </th>
          <th pSortableColumn="name">
          Name
          <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
        </th>
      <td>
          <span class="badge bg-primary text-white" *ngIf="category.status">Enable</span>
          <span class="badge bg-danger text-white" *ngIf="!category.status">Disable</span>
        </td>-->