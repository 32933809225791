import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FirebaseService, NotifyService } from 'src/app/core/services';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth,createUserWithEmailAndPassword } from 'firebase/auth';
import * as moment from 'moment';
import { Validators } from '@angular/forms';
import { FieldConfig, FormConfig } from 'ngx-nomad-form';
//import * as admin from 'firebase-admin';
//import * as serviceAccount from '/Users/tt242/Downloads/aaa.json';


@Component({
  selector: 'app-admin-management',
  templateUrl: './admin-management.component.html',
  styleUrls: ['./admin-management.component.scss']
})

export class AdminManagementComponent implements OnInit {

  users : any = [];
  display: boolean = false;
  user : any;


  firebase = {
    apiKey: "AIzaSyC6pJSrQG-TZq_VEPxjNMwqY-6RAR5oI00",
    authDomain: "showcase-440e9.firebaseapp.com",
    databaseURL: "https://showcase-440e9-default-rtdb.firebaseio.com",
    projectId: "showcase-440e9",
    storageBucket: "showcase-440e9.appspot.com",
    messagingSenderId: "393424088211",
    appId: "1:393424088211:web:c3622e5f4b36db506cf166"
  }

  /*firebase = {
    apiKey: "AIzaSyCYsHJNYOXr76j_OlwQrf6KUs-AUKblfhQ",
    authDomain: "showcasetest-67e9e.firebaseapp.com",
    projectId: "showcasetest-67e9e",
    storageBucket: "showcasetest-67e9e.appspot.com",
    messagingSenderId: "863574162156",
    appId: "1:863574162156:web:3335685865f760bdfac3c9",
    measurementId: "G-WJT6LT9BNZ"
    }*/ 
    //testDB kkk
  
  app = initializeApp(this.firebase);
  authInstance = getAuth(this.app)

  constructor(private firestore : FirebaseService, public notify : NotifyService, 
    private afs: AngularFirestore) { }

  ngOnInit() {
    this.fetch();
  }

  add(){
    this.user = [];
    this.display= true;
  }

  refresh(){
    this.fetch();
  }

  fetch() {
    this.afs.collection('users').valueChanges().subscribe((res : any) => {
      this.users = res;
      
    });
  }

  delete(id: string){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.firestore.deleteDocument('users', id).then(val => {
          Swal.fire(
            'Deleted!',
            'This user has been deleted.',
            'success'
          );
          this.fetch();
        }, err => {
          this.notify.error2("hmm :(");
        });
      }
    })
  }

  saver(formData: any){
   
    this.addUser(formData);
    
    this.display = false;
    this.user = [];
  }

  addUser(formData: any){

    this.registerUser(formData.email, formData.password);
  }

  async registerUser(email: string, password: string): Promise<void> {
    
    try {
      const userCredential = await createUserWithEmailAndPassword(this.authInstance, email, password);
      const user = userCredential.user;
      console.log('User registered:', user.uid);
      this.afs.collection('users').doc(user.uid).set({
        id: user.uid,
        email: email,
        created_at: moment().format()
      });
    } catch (error) {
      console.error('Error registering user:', error.message);
    }
  }

  /*admin.initializeApp({
    credential: admin.credential.cert(serviceAccount),
    // Other options if needed
  });

  deleteUser(uid: string): void {
    admin
      .auth()
      .deleteUser(uid)
      .then(() => {
        console.log('User deleted successfully');
        this.fetch(); // Refresh the user list after deletion
      })
      .catch((error) => {
        console.error('Error deleting user:', error);
      });
  }*/



}
