import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { Category, Product } from 'src/app/core/models';
import { FirebaseService, NotifyService } from 'src/app/core/services';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Group } from 'src/app/core/models/group';
import { Router } from '@angular/router';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  groups : Group[] = [];
  products : Product[] = [];
  display: boolean = false;
  group : any;
  count: any=0;
  countGroup : any = [];

  constructor(private firestore : FirebaseService, public notify : NotifyService, 
    private afs: AngularFirestore, public router : Router) { }

  ngOnInit() {
    //this.fetch();
    this.fetchProducts();
  }

  details(){
    this.router.navigate(['admin', 'products']);
  }

  add(){
    this.group = [];
    this.display= true;
  }

  refresh(){
    this.fetch();
  }

  fetch() {
    this.afs.collection('groups').valueChanges().subscribe((res : any) => {
      this.groups = res;
      console.log(this.groups);
    });
  }

  fetchProducts() {

    this.afs.collection('groups').valueChanges().subscribe((res : any) => {
      this.groups = res;

      for(let i=0; i<this.groups.length;i++){
        if(this.groups[i].id=='lUm00KQUdhoaVs7kLDEW'){         
          this.groups.splice(i,1);
        }}
      
      //registering a new product through add product

      this.afs.collection('products').valueChanges().subscribe((res : any) => {
        this.products = res;

        this.countGroup=[];
        for (let i = 0; i < this.groups.length; i++) {
          this.products.forEach( x=> this.count = this.count+ this.checkNum(x.group, this.groups[i].id)); 
          this.countGroup.push(this.count);
          this.count=0;
        }

        
        for (let i = 0; i < this.countGroup.length; i++) {
          this.groups[i].stock = this.countGroup[i];
        }
        
      });

    });
  }

  /*
  for(let i=0; i<this.groups.length;i++){
    if(this.groups[i].value=='lUm00KQUdhoaVs7kLDEW'){
      this.reserve=this.groups[i];
      this.groups.splice(i,1);
    }
  }*/

  checkNum(x:any,y:any){
    if(x==y){
      return 1;
    }else{
      return 0;
    }
  }

  delete(id: string){

    console.log(id)
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.firestore.deleteDocument('groups', id).then(val => {
          Swal.fire(
            'Deleted!',
            'This product type has been deleted.',
            'success'
          );
          this.fetchProducts();
        }, err => {
          this.notify.error2("Nope :(");
        });
      }
    })
  }

  edit(group: Group[]){
    this.group = group;
    this.display = true;
  }

  saverG(formData: any){
    if(!formData.isAddForm) {
      this.updateGroup(formData);
    } else {
      this.addGroup(formData);
    }
    this.display = false;
    this.group = [];
  }

  updateGroup(formData: any){
    console.log('update');
    let data = {
      //uid: formData.uid,
      name: formData.name,
      //stock: 0,
      updated_at: moment().format()
    };

    this.afs.doc<any>(`groups/${formData.id}`).update(data).then(() => {
      this.fetchProducts();
    }, err => {
      this.notify.error2("Nope :(");
    });
  }

  addGroup(formData: any){
    console.log('add');
    let data = {
      id: this.afs.createId(),
      //uid: this.afs.createId(),
      name: formData.name,
      stock: 0,
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("groups").doc(data.id).set(data).then(() => {
      this.fetchProducts();
    }, err => {
      this.notify.error2("Ouch :(");
    });
  }


}


/*for (let i = 0; i < this.groups.length; i++) {
          this.products.forEach( x=> this.count = this.count+ this.checkNum(x.group, this.groups[i].uid)); 
          this.countGroup[i].uid= this.groups[i].uid;
          this.countGroup[i].name=this.groups[i].name;
          this.countGroup[i].stock=this.count;
          this.count=0;
        }
        console.log(this.countGroup);*/
/*for (let i = 0; i < this.groups.length; i++) {
          this.products.forEach( x=> this.count = this.count+ this.checkNum(x.group, this.groups[i].uid)); 
          this.countGroup[i].uid= this.groups[i].uid;
          this.countGroup[i].name=this.groups[i].name;
          this.countGroup[i].stock=this.count;
          this.count=0;
        }
        console.log(this.countGroup);*/