import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirebaseService, NotifyService } from 'src/app/core/services';
import { Product, Category } from 'src/app/core/models';
import { Group } from 'src/app/core/models/group';
import { map } from 'rxjs/operators';
import { Timestamp } from 'firebase/firestore';
import * as mqtt from 'mqtt/dist/mqtt';
import { AngularFireStorage } from '@angular/fire/compat/storage'; // Use the correct import path
import { Router } from '@angular/router';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  //@ViewChild('videoElement', { static: false })

  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;
  @ViewChild('barcodeInput', { static: false }) barcodeInput: ElementRef;



  videoElementRef!: ElementRef<HTMLVideoElement>;

  products : Product[] = [];
  categories : Category[] = [];
  groupsToUpdate : Group[] = [];
  display: boolean = false;
  product : any;
  check2 :any=0;
  displayScan: boolean = false;
  displayScanBarcode: boolean = false;
  productExpChk: any;
  tempMqtt: any;
  tempMqtt2: any;
  downloadURL: string;
  
  public showCamera: boolean = false;
  public previewImageUrl: string | ArrayBuffer | null = null;
  public previewContainerStyles: { [key: string]: string } = {};
  

  mqttServer = 'mqtt://broker.emqx.io';
  mqttPort = 1883;
  //mqttServer = 'ws://broker.emqx.io';
  //mqttPort = 8083;
  mqttTopic = 'bins';

  expiryData=[
    {
    'label': 'Squid',
    'value': "2022-11-25"
   },{
    'label': 'Sea Bass',
    'value': "2022-11-30"
   },{
    'label': 'Merona',
    'value': "2022-12-05"
   },{
    'label': 'Brill',
    'value': "2022-12-10"
   },{
    'label': 'Skull Fish',
    'value': "2022-12-15"
   },{
    'label': 'Tuna',
    'value': "2022-12-20"
   }]

  constructor(private firestore : FirebaseService, public notify : NotifyService,
    private afs: AngularFirestore, private storage: AngularFireStorage,public router : Router) { }

  ngOnInit() {
    this.fetch();
    
    const client = mqtt.connect(this.options);
    client.subscribe('confirmed',1); 
    client.on('message', (topic: string, message: Buffer) => {
      if (topic === 'confirmed') {
        this.tempMqtt = message.toString(); 
        if(this.tempMqtt == this.tempMqtt2){
          this.sendSpecialMessage();
        }else{
          console.log('Received confirmation:', message.toString());
          this.tempMqtt2 = message.toString();
        }
        
      }
    });
    

  }

  details(){
    this.router.navigate(['admin', 'details']);
  }

  qosLevel = 1; // Set the QoS level here

  

  sendMqttMessage() {
    //const client = mqtt.connect(this.mqttServer, { port: this.mqttPort });
    const client = mqtt.connect('ws://broker.emqx.io:8083/mqtt');
    
  
    client.on('connect', () => {
      console.log('Connected to MQTT broker');
  
      const message = {
        cell_count: ['cell1', 'cell2','cell1', 'cell2'],
      };
  
      // Convert the message to JSON
      const messageJson = JSON.stringify(message);
  
      // Publish the JSON message to the topic
      client.publish(this.mqttTopic, messageJson,{ qos: this.qosLevel });
  
      console.log('Message sent:', messageJson);
  
      // Disconnect from the MQTT broker
      client.end();
    });
  
    client.on('error', (error) => {
      console.error('Error:', error);
    });
  }

  ntakePicture(){
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.showCamera = true;
      this.startCamera();
    } else {
      console.error('getUserMedia is not supported by this browser.');
    }
  }

  async startCamera() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream && this.videoElementRef) {
        this.videoElementRef.nativeElement.srcObject = stream;
        
      }
    } catch (error) {
      console.error('Error accessing the camera:', error);
    }
    
  }

  options: mqtt.IClientOptions = {
    protocol: 'wss',
    hostname: 'broker.emqx.io',
    port: 8084,
    path: '/mqtt',
  };

  sendSpecialMessage() {

    const data = {"cell_count":["11","12","13","14"],"quantity":["1","2","3","4"]};
    const client = mqtt.connect(this.options);
  
    client.on('connect', () => {
      console.log('Connected to MQTT broker');

      // Convert the message to JSON
      const messageJson = JSON.stringify(data);
  
      // Publish the JSON message to the topic
      client.publish(this.mqttTopic, messageJson);
  
      console.log('Message sent:', messageJson);
  
      // Disconnect from the MQTT broker
      client.end();
    });
  
    client.on('error', (error) => {
      console.error('Error:', error);
      //console.error('Reason:', error.message); 
    });
  }

  capturePicture() {
    const videoElement = this.videoElementRef.nativeElement;
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    canvas.getContext('2d')?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    this.previewImageUrl = canvas.toDataURL('image/png');
    this.stopCamera();
  }

  stopCamera() {
    const stream = this.videoElementRef.nativeElement.srcObject as MediaStream;
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      this.videoElementRef.nativeElement.srcObject = null;
    }
  }

  add(){
    this.product = [];
    this.display= true;
  }

  scan(){
    this.displayScan= true;
  }

  scanBarcode(){
    this.displayScanBarcode= true;
  }

  refresh(){
    this.fetch();
  }

  async fetch() {
    this.afs.collection('products').valueChanges().subscribe((res : any) => {
      this.products = res;
      //console.log(this.products);
    });

    this.afs.collection('categories').valueChanges().subscribe((res : any) => {
      this.categories = res;
      this.products.forEach(async (element, key) => {
        this.products[key].category = await this.findCategory(element.category);
      });
    });
  }

  delete(id: string){
    Swal.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.firestore.deleteDocument('products', id).then(val => {
          Swal.fire(
            'Deleted!',
            'This product has been deleted.',
            'success'
          );
          this.fetch();
        }, err => {
          this.notify.error2("Oup's une erreur est survenu :(");
        });
      }
    })
  }

  edit(product: Product[]){
    this.product = product;
    this.display = true;
  }

  scannedBarcode = '';
  scannedBarcodes: string[] = [];

  onBarcodeScanned() {
    // Add scanned barcode to the array
    this.scannedBarcodes.push(this.scannedBarcode);
    this.scannedBarcode = ''; // Clear the input field
  }

  /*saveBarcode() {
    // Implement saving scanned barcodes to your database here
    // You can use a service to make an HTTP request to your backend API.

    console.log(this.scannedBarcodes)
    console.log('savebarcode called')
    this.displayScanBarcode = false;
    this.scannedBarcodes=[];
  }*/

  saver(formData: any){
    if(!formData.isAddForm) {
      this.updateProduct(formData);
    } else {
      this.addProduct(formData);

      //add or not new group
      this.addGroup(formData.group);
      
    }
    this.display = false;
    this.product = [];
    this.clearPreview();
  }

  clear(){
    this.scannedBarcodes=[];
  }


  setAutofocus() {
  this.barcodeInput.nativeElement.focus();
}

  saverBarcode(formDataArray: any[]) {
    console.log('save is clicked');

    //console.log(formDataArray)
  
    // Iterate through each formData in the array
    let uniqueFormDataArray = Array.from(new Set(formDataArray));
    
    console.log(uniqueFormDataArray)

    uniqueFormDataArray.forEach((formData) => {
      this.addQrProduct(formData);
      //this.addGroup(formData.group);
    });
  
    this.displayScanBarcode = false;
    this.product = [];
    this.scannedBarcodes=[];
    
  }

  addGroup(formData: any){

    this.afs.collection('groups').valueChanges().subscribe((res : any) => {
      this.groupsToUpdate = res;

      //console.log(this.groupsToUpdate);
      for(let i=0;i<this.groupsToUpdate.length;i++){
        if(formData==this.groupsToUpdate[i].id){
          this.check2=1;
          //console.log('is equal')
          
        }
      }
      //console.log(this.check2)

      if(this.check2==0){
        console.log('db updated')
        let data = {
          id: this.afs.createId(),
          name: formData,
          stock: 1,
          created_at: moment().format(),
          updated_at: moment().format()
        };
  
        this.afs.collection("groups").doc(data.id).set(data).then(() => {
          this.fetchGroup();
        }, err => {
          this.notify.error2("Oup's une erreur est survenu :(");
        });
  
        this.check2=1;
      }

      //update product.group id after
      for (let i=0;i<this.groupsToUpdate.length;i++){
        if(this.groupsToUpdate[i].name==formData){

          for (let j=0; j<this.products.length;j++){
            if (this.products[j].group==formData){
              
              this.products[j].group=this.groupsToUpdate[i].id;

              this.afs.doc<any>(`products/${this.products[j].id}`).update({group: this.products[j].group}).then(() => {
                this.fetch();
              }, err => {
                this.notify.error2("Oup's une erreur est survenu :(");
              });
            }
          }
        }
      }
      
    }); 
  }

  fetchGroup(){
    this.afs.collection('groups').valueChanges().subscribe((res : any) => {
      this.groupsToUpdate = res;
      console.log(this.groupsToUpdate);
    });
  }

  async findCategory(category_id: string){
    return new Promise((resolve, reject) => {
      this.afs.collection<Category>('categories').doc(category_id).ref.get().then((doc) => {
        if (doc.exists) {
            resolve(doc.data().name);
        } else {
            return 'Doc does not exits';
        }
    })
    .catch((err) => {
        console.error(err);
    });
    });
  }

  updateProduct(formData: any){

    if(formData.expiry=='auto'){
      if(formData.group=='9rYQwdwUvkPpugFQ8qm3'){
        this.productExpChk = moment().add(12, 'hours').format()
      }else if(formData.group=='CVLAdrRIeyOMHubw1c5v'){
        this.productExpChk = moment().add(13, 'hours').format()
      }else if(formData.group=='HwkW2HIlVs16LqjZHH0p'){
        this.productExpChk = moment().add(14, 'hours').format()
      }else if(formData.group=='KAMZO4hBKLNHwdyslDEh'){
        this.productExpChk = moment().add(15, 'hours').format()
      }else if(formData.group=='OV6fPvy7bAiE8VOSfz83'){
        this.productExpChk = moment().add(16, 'hours').format()
      }else if(formData.group=='OqNISRsWU36IAvltb0Zb'){
        this.productExpChk = moment().add(17, 'hours').format()
      }else {
        this.productExpChk = moment().add(24, 'hours').format()
      }
    }else{
      this.productExpChk = formData.expiry;
    }

    let data = {
      name: formData.name,
      price: formData.price,
      group: formData.group,
      row: formData.row,
      column: formData.column,
      expiry: this.productExpChk,
      imageUrl: this.downloadURL,
      category: formData.category,
      updated_at: moment().format()
    };

    if (formData.category=='19He5XlTo9nMzI13ASNE'){
      data.row='1';
    }else if (formData.category=='OKwqUulyfMlNDipArnrL'){
      data.row='2';
    }else if (formData.category=='oQ31KXBVZs1UWOQs7mjB'){
      data.row='3';
    }else{
      data.row='1';
    }
    

    console.log(data);

    this.afs.doc<any>(`products/${formData.id}`).update(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addProduct(formData: any){

    //console.log(formData);
    //console.log(this.expiryData[0].value)

    if(formData.expiry=='auto'){
      if(formData.group=='9rYQwdwUvkPpugFQ8qm3'){
        this.productExpChk = moment().add(12, 'hours').format()
      }else if(formData.group=='CVLAdrRIeyOMHubw1c5v'){
        this.productExpChk = moment().add(13, 'hours').format()
      }else if(formData.group=='HwkW2HIlVs16LqjZHH0p'){
        this.productExpChk = moment().add(14, 'hours').format()
      }else if(formData.group=='KAMZO4hBKLNHwdyslDEh'){
        this.productExpChk = moment().add(15, 'hours').format()
      }else if(formData.group=='OV6fPvy7bAiE8VOSfz83'){
        this.productExpChk = moment().add(16, 'hours').format()
      }else if(formData.group=='OqNISRsWU36IAvltb0Zb'){
        this.productExpChk = moment().add(17, 'hours').format()
      }else {
        this.productExpChk = moment().add(24, 'hours').format()
      }
    }else{
      this.productExpChk = formData.expiry;
    }

    let data = {
      id: this.afs.createId(),
      name: formData.name,
      price: formData.price,
      group: formData.group,
      row: '0',
      column: formData.column,
      expiry: this.productExpChk,
      //imageUrl: formData.imageUrl,
      imageUrl: this.downloadURL,
      category: formData.category,
      created_at: moment().format(),
      updated_at: moment().format()
    };

    console.log(formData)

    if (formData.category=='19He5XlTo9nMzI13ASNE'){
      data.row='1';
    }else if (formData.category=='OKwqUulyfMlNDipArnrL'){
      data.row='2';
    }else if (formData.category=='oQ31KXBVZs1UWOQs7mjB'){
      data.row='3';
    }else{
      data.row='1';
    }
    
    


    //console.log(formData.group.name[5])

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addQrProduct( dataIn: any){

    //rechange product emit callback and saverBarcode

    let formData;

    console.log(dataIn)

    //price (4) - group(2) - category(2) - expiry(4) BARCODE DATA FORMAT
    if (dataIn == '0100001010120231111'){
  
    // Input string with 10 being in decimal form
    const QRprice: number = parseInt((dataIn.slice(0, 5)), 10);
    const QRgroup: number = parseInt((dataIn.slice(5, 7)), 10);
    const QRcategory: number = parseInt((dataIn.slice(7, 9)), 10);
    const QRname: number = parseInt((dataIn.slice(9, 11)), 10);
    const QRexpiry: number = parseInt((dataIn.slice(11, 19)), 10);

    // Print the price
    console.log("Price:", QRprice);
    console.log("Group:", QRgroup);
    console.log("Category:", QRcategory);
    console.log("Expiry:", QRname);
    console.log("Expiry:", QRexpiry);


    formData = {
      name: "삼치",
      price: "1000",
      group: "7gDBp4IAr0lxXUby5EPf",
      column: "1",
      expiry: "2023-09-30",
      imageUrl: "https://i.namu.wiki/i/RLkeYjYSXEtQn7V6u7CqKCIe7LelAbwQ11Y9SU7L9VlUAA7Z4uXm7KGOHLvvgRGGSPhiRyDjz-Vc2fnTx5rcFA.webp",
      category: "19He5XlTo9nMzI13ASNE",
    };
    
  }else if(dataIn == '0100002010120231111'){

    formData = {
      name: "참치",
      price: "1000",
      group: "7gDBp4IAr0lxXUby5EPf",
      column: "2",
      expiry: "2023-09-30",
      imageUrl: "https://blog.kakaocdn.net/dn/UcYTF/btrc8iaKZGf/u5YTgyKwKlkrdKAA8dRmQk/img.jpg",
      category: "19He5XlTo9nMzI13ASNE",
    };

    }else if(dataIn == '0100003010120231111'){

      formData = {
        name: "노래미",
        price: "1000",
        group: "7gDBp4IAr0lxXUby5EPf",
        column: "3",
        expiry: "2023-10-30",
        imageUrl: "https://imgs.kshop.co.kr/goods/736/269736_g_20160722182914.jpg",
        category: "19He5XlTo9nMzI13ASNE",
      };
  
    }else if(dataIn == '0100004010120231111'){

      formData = {
        name: "멸치",
        price: "1000",
        group: "7gDBp4IAr0lxXUby5EPf",
        column: "4",
        expiry: "2023-10-30",
        imageUrl: "https://www.biozoa.co.kr/data/goods/1/2020/07/277_tmp_9d0f3408e4e91ca1c1f26c8425768e330379list1.jpg",
        category: "19He5XlTo9nMzI13ASNE",
      };
  
      }else{

        formData = {
          name: "새우",
          price: "1000",
          group: "7gDBp4IAr0lxXUby5EPf",
          column: "4",
          expiry: "2022-11-11",
          imageUrl: "https://www.biozoa.co.kr/data/goods/1/2020/07/277_tmp_9d0f3408e4e91ca1c1f26c8425768e330379list1.jpg",
          category: "19He5XlTo9nMzI13ASNE",
        };
    
        }
    

    if(formData.expiry=='auto'){
      if(formData.group=='9rYQwdwUvkPpugFQ8qm3'){
        this.productExpChk = moment().add(12, 'hours').format()
      }else if(formData.group=='CVLAdrRIeyOMHubw1c5v'){
        this.productExpChk = moment().add(13, 'hours').format()
      }else if(formData.group=='HwkW2HIlVs16LqjZHH0p'){
        this.productExpChk = moment().add(14, 'hours').format()
      }else if(formData.group=='KAMZO4hBKLNHwdyslDEh'){
        this.productExpChk = moment().add(15, 'hours').format()
      }else if(formData.group=='OV6fPvy7bAiE8VOSfz83'){
        this.productExpChk = moment().add(16, 'hours').format()
      }else if(formData.group=='OqNISRsWU36IAvltb0Zb'){
        this.productExpChk = moment().add(17, 'hours').format()
      }else {
        this.productExpChk = moment().add(24, 'hours').format()
      }
    }else{
      this.productExpChk = formData.expiry;
    }

    let data = {
      id: this.afs.createId(),
      name: formData.name,
      price: formData.price,
      group: formData.group,
      row: '0',
      column: formData.column,
      expiry: this.productExpChk,
      imageUrl: formData.imageUrl,
      //imageUrl: this.downloadURL,
      category: formData.category,
      created_at: moment().format(),
      updated_at: moment().format()
    };

    if (formData.category=='19He5XlTo9nMzI13ASNE'){
      data.row='1';
    }else if (formData.category=='OKwqUulyfMlNDipArnrL'){
      data.row='2';
    }else if (formData.category=='oQ31KXBVZs1UWOQs7mjB'){
      data.row='3';
    }else{
      data.row='1';
    }

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest(){

    let data = {
      id: this.afs.createId(),
      //name: String(Timestamp.now().seconds).slice(-3)+' fish1',
      name: 'Salmon',
      price: '1000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '1',
      column: '1',
      expiry: '2022-12-31',

      imageUrl: 'assets/img/salmon.png',

      recipe1: 'assets/img/salmon1.png',
      recipe1items: ['Salmon','Oil','Salt'],
      recipe1name: 'Roasted Salmon',
      
      recipe2: 'assets/img/salmon2.png',
      recipe2items: ['Salmon','Spinach','Oil'],
      recipe2name: 'Salmon Salad',

      recipe3: 'assets/img/salmon3.png',
      recipe3items: ['Salmon','Oil','Water'],
      recipe3name: 'Salmon Stew',

      serving: 1,
      calories:206,
      fat:13,
      satFat: 3,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein:22,
      vc: 0,
      va: 40,
      vk: 0,
      calcium: 9,
      iron: 0.4,
      potassium: 330,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });

    this.addTest2();
    this.addTest3();
    this.addTest4();
    this.addTest5();
    this.addTest6();
    this.addTest7();
    this.addTest8();
    this.addTest9();
    this.addTest10();
    this.addTest11();
    this.addTest12();
    this.addTest13();
    this.addTest14();
    this.addTest15();
    this.addTest16();
  }

  addTest2(){

    let data = {
      id: this.afs.createId(),
      name: 'Squid',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '1',
      column: '2',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/squid.png',

      recipe1: 'assets/img/squid1.png',
      recipe1items: ['Squid','Salt','Oil'],
      recipe1name: 'Roasted Squid',
      
      recipe2: 'assets/img/squid2.png',
      recipe2items: ['Squid','Oil','Flour'],
      recipe2name: 'Fried Squid',

      recipe3: 'assets/img/squid3.png',
      recipe3items: ['Kimchi','Squid','Oil'],
      recipe3name: 'Squid Kimchi',

      serving: 1,
      calories:92,
      fat:1.2,
      satFat: 0.2,
      carbohydrates: 1.4,
      fiber: 0,
      sugar: 0,
      protein:15.6,
      vc: 0,
      va: 0,
      vk: 0,
      calcium: 6,
      iron: 0.6,
      potassium: 220,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest3(){

    let data = {
      id: this.afs.createId(),
      name: 'Shrimp',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '1',
      column: '3',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/shrimp.png',

      recipe1: 'assets/img/shrimp1.png',
      recipe1items: ['Shrimp','Oil','Salt'],
      recipe1name: 'Fried Shrimp',
      
      recipe2: 'assets/img/shrimp2.png',
      recipe2items: ['Shrimp','Salt','Water'],
      recipe2name: 'Shrimp Paste',

      recipe3: 'assets/img/shrimp3.png',
      recipe3items: ['Shrimp','Oil','Onion'],
      recipe3name: 'Shrimp Salad',

      serving: 10,
      calories:80,
      fat:1.5,
      satFat: 0.5,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein:17,
      vc: 0,
      va: 0,
      vk: 0,
      calcium: 30,
      iron: 1,
      potassium: 300,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest4(){

    let data = {
      id: this.afs.createId(),
      name: 'Mackeral',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '1',
      column: '4',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/mackeral.png',

      recipe1: 'assets/img/mackeral1.png',
      recipe1items: ['Mackeral','Oil','Salt'],
      recipe1name: 'Fried Mackeral',
      
      recipe2: 'assets/img/mackeral2.png',
      recipe2items: ['Mackeral','Kimchi','Oil'],
      recipe2name: 'Mackeral Kimchi Stew',

      recipe3: 'assets/img/mackeral3.png',
      recipe3items: ['Chilli','Oil','Mackeral'],
      recipe3name: 'Spicy Mackeral',

      serving:50,
      calories:60,
      protein:6,
      fat:4,
      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest5(){

    let data = {
      id: this.afs.createId(),
      //name: String(Timestamp.now().seconds).slice(-3)+' fish1',
      name: 'Beef',
      price: '1000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '2',
      column: '1',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/beef.png',

      recipe1: 'assets/img/beef1.png',
      recipe1items: ['Beef','Oil','Water'],
      recipe1name: 'Fried Beef',
      
      recipe2: 'assets/img/beef2.png',
      recipe2items: ['Beef','Oil','water'],
      recipe2name: 'Grilled Steak',

      recipe3: 'assets/img/beef3.png',
      recipe3items: ['Beef','Onion','Paprika'],
      recipe3name: 'Beef and Vegetable Stir-Fry',

      serving: 1,
      calories:500,
      fat:40,
      satFat: 15,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein:40,
      vc: 0,
      va: 0,
      vk: 0,
      calcium: 15,
      iron: 2.1,
      potassium: 300,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });

  }

  addTest6(){

    let data = {
      id: this.afs.createId(),
      name: 'Pork',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '2',
      column: '2',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/pork.png',

      recipe1: 'assets/img/pork1.png',
      recipe1items: ['Pork','Oil','Salt'],
      recipe1name: 'Fried Pork',
      
      recipe2: 'assets/img/pork2.png',
      recipe2items: ['Kimchi','Pork','Oil'],
      recipe2name: 'Stir Fried Kimchi Pork',

      recipe3: 'assets/img/pork3.png',
      recipe3items: ['Pork','Oil','Water'],
      recipe3name: 'Steamed Pork',

      serving: 1,
      calories:780,
      fat:65,
      satFat: 24,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein:55,
      vc: 0,
      va: 0,
      vk: 0,
      calcium: 15,
      iron: 1.0,
      potassium: 300,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest7(){

    let data = {
      id: this.afs.createId(),
      name: 'Chicken',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '2',
      column: '3',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/chicken.png',

      recipe1: 'assets/img/chicken1.png',
      recipe1items: ['Chicken','Salt','Water'],
      recipe1name: 'Fried Chicken',
      
      recipe2: 'assets/img/chicken2.png',
      recipe2items: ['Chicken','Salt','Water'],
      recipe2name: 'Steamed Chicken',

      recipe3: 'assets/img/chicken3.png',
      recipe3items: ['Chicken','Paprika','Oil'],
      recipe3name: 'Stuffed Chicken',

      serving: 1,
      calories:239,
      fat:14,
      satFat: 4,
      carbohydrates: 0,
      fiber: 0,
      sugar: 0,
      protein:27,
      vc: 0,
      va: 0,
      vk: 0,
      calcium: 15,
      iron: 1.0,
      potassium: 300,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest8(){

    let data = {
      id: this.afs.createId(),
      name: 'Egg',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '2',
      column: '4',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/egg.png',

      recipe1: 'assets/img/egg1.png',
      recipe1items: ['Egg','Oil','Salt'],
      recipe1name: 'Sunny Side Up',
      
      recipe2: 'assets/img/egg2.png',
      recipe2items: ['Egg','Oil','Water'],
      recipe2name: 'Scrambled Egg',

      recipe3: 'assets/img/egg3.png',
      recipe3items: ['Egg','Salt','Water'],
      recipe3name: 'Boiled Egg',

      serving: 30,
      calories:2100,
      fat:150,
      satFat: 45,
      carbohydrates: 15,
      fiber: 0,
      sugar: 0,
      protein:210,
      vc: 120,
      va: 1600,
      vk: 0,
      calcium: 300,
      iron: 3,
      potassium: 1200,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest9(){

    let data = {
      id: this.afs.createId(),
      //name: String(Timestamp.now().seconds).slice(-3)+' fish1',
      name: 'Cabbage',
      price: '1000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '3',
      column: '1',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/cabbage.png',

      recipe1: 'assets/img/cabbage1.png',
      recipe1items: ['Cabbage','Oil','Water'],
      recipe1name: 'Stir-Fried Cabbage',
      
      recipe2: 'assets/img/cabbage2.png',
      recipe2items: ['Cabbage','Oil','Water'],
      recipe2name: 'Stuffed Cabbage Rolls',

      recipe3: 'assets/img/cabbage3.png',
      recipe3items: ['Cabbage','Oil','Water'],
      recipe3name: 'Cabbage Coleslaw',

      serving: 1,
      calories:180,
      fat:1,
      satFat: 0.2,
      carbohydrates: 40,
      fiber: 15,
      sugar: 8,
      protein:12,
      vc: 60,
      va: 900,
      vk: 700,
      calcium: 150,
      iron: 1.5,
      potassium: 800,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });

  }

  addTest10(){

    let data = {
      id: this.afs.createId(),
      name: 'Paprika',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '3',
      column: '2',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/paprika.png',

      recipe1: 'assets/img/peparika1.png',
      recipe1items: ['Beef','Paprika','Oil'],
      recipe1name: 'Stuffed Bell Peppers',
      
      recipe2: 'assets/img/peparika2.png',
      recipe2items: ['Chicken','Paprika','Oil'],
      recipe2name: 'Paprika Chicken',

      recipe3: 'assets/img/peparika3.png',
      recipe3items: ['Paprika','Oil','Salt'],
      recipe3name: 'Roasted Bell Peppers',

      serving: 1,
      calories:45,
      fat:0.5,
      satFat: 0.1,
      carbohydrates: 10,
      fiber: 3,
      sugar: 4,
      protein:2,
      vc: 20,
      va: 0,
      vk: 0,
      calcium: 30,
      iron: 0.6,
      potassium: 250,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest11(){

    let data = {
      id: this.afs.createId(),
      name: 'Squash',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '3',
      column: '3',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/squash.png',

      recipe1: 'assets/img/suqash1.png',
      recipe1items: ['Squash','Onion','water'],
      recipe1name: 'Squash Soup',
      
      recipe2: 'assets/img/squash2.png',
      recipe2items: ['Cream','Squash','Water'],
      recipe2name: 'Creamy Squash Soup',

      recipe3: 'assets/img/squash3.png',
      recipe3items: ['Squash','Oil','Water'],
      recipe3name: 'Stuffed Squash',

      serving: 1,
      calories:36,
      fat:0.4,
      satFat: 0.1,
      carbohydrates: 8,
      fiber: 2,
      sugar: 3,
      protein:2,
      vc: 20,
      va: 400,
      vk: 0,
      calcium: 20,
      iron: 0.6,
      potassium: 400,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest12(){

    let data = {
      id: this.afs.createId(),
      name: 'Chinese Cabbage',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '3',
      column: '4',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/chinese.png',

      recipe1: 'assets/img/squash1.png',
      recipe1items: ['Cabbage','Oil','Water'],
      recipe1name: 'Stir-Fried Cabbage',
      
      recipe2: 'assets/img/squash2.png',
      recipe2items: ['Cabbage','Oil','Water'],
      recipe2name: 'Stuffed Cabbage Rolls',

      recipe3: 'assets/img/squash3.png',
      recipe3items: ['Cabbage','Oil','Water'],
      recipe3name: 'Cabbage Coleslaw',

      serving: 1,
      calories:180,
      fat:1,
      satFat: 0.2,
      carbohydrates: 40,
      fiber: 15,
      sugar: 8,
      protein:12,
      vc: 60,
      va: 900,
      vk: 700,
      calcium: 150,
      iron: 1.5,
      potassium: 800,
      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest13(){

    let data = {
      id: this.afs.createId(),
      //name: String(Timestamp.now().seconds).slice(-3)+' fish1',
      name: 'Carrot',
      price: '1000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '4',
      column: '1',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/carrot.png',

      recipe1: 'assets/img/carrot1.png',
      recipe1items: ['Carrot','Ginger','Water'],
      recipe1name: 'Carrot Ginger Soup',
      
      recipe2: 'assets/img/carrot2.png',
      recipe2items: ['Honey','Oil','Carrot'],
      recipe2name: 'Honey Glazed Carrot',

      recipe3: 'assets/img/carrot3.png',
      recipe3items: ['Carrot','Raisin','Oil'],
      recipe3name: 'Carrot & Raisin Salad',

      serving: 1,
      calories:40,
      fat:0.1,
      satFat: 0,
      carbohydrates: 9,
      fiber: 2,
      sugar: 4,
      protein:1,
      vc: 20,
      va: 0,
      vk: 0,
      calcium: 40,
      iron: 0.5,
      potassium: 300,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });

  }

  addTest14(){

    let data = {
      id: this.afs.createId(),
      name: 'Spinach',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '4',
      column: '2',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/spinach.png',

      recipe1: 'assets/img/spinach1.png',
      recipe1items: ['Spinach','Chicken','Oil'],
      recipe1name: 'Spinach Stuffed Chicken',
      
      recipe2: 'assets/img/spinach2.png',
      recipe2items: ['Cream','Oil','Spinach'],
      recipe2name: 'Creamed Spinach',

      recipe3: 'assets/img/spinach3.png',
      recipe3items: ['Spinach','Oil','Water'],
      recipe3name: 'Spinach Cannelloni',

      serving: 1,
      calories:50,
      fat:1,
      satFat: 0.2,
      carbohydrates: 10,
      fiber: 4,
      sugar: 1,
      protein:6,
      vc: 60,
      va: 18000,
      vk: 500,
      calcium: 100,
      iron: 3.5,
      potassium: 800,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest15(){

    let data = {
      id: this.afs.createId(),
      name: 'Onion',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '4',
      column: '3',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/onion.png',

      recipe1: 'assets/img/onion1.png',
      recipe1items: ['Onion','Salt','Water'],
      recipe1name: 'Onion Soup',
      
      recipe2: 'assets/img/onion2.png',
      recipe2items: ['Onion','Oil','Salt'],
      recipe2name: 'Fried Onion',

      recipe3: 'assets/img/onion3.png',
      recipe3items: ['Onion','Oil','Water'],
      recipe3name: 'Caramelized Onion',

      serving: 1,
      calories:40,
      fat:0.1,
      satFat: 0,
      carbohydrates: 9,
      fiber: 1.7,
      sugar: 4,
      protein:1,
      vc: 7,
      va: 0,
      vk: 0,
      calcium: 30,
      iron: 0.2,
      potassium: 150,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addTest16(){

    let data = {
      id: this.afs.createId(),
      name: 'Radish',
      price: '10000',
      group: '7gDBp4IAr0lxXUby5EPf',
      row: '4',
      column: '4',
      expiry: '2022-12-31',
      imageUrl: 'assets/img/radish.png',

      recipe1: 'assets/img/radish1.png',
      recipe1items: ['Radish','Onion','Water'],
      recipe1name: 'Radish Soup',
      
      recipe2: 'assets/img/radish2.png',
      recipe2items: ['Radish','Salt','Water'],
      recipe2name: 'Pickled Radish',

      recipe3: 'assets/img/radish3.png',
      recipe3items: ['Radish','Salt','Water'],
      recipe3name: 'Radish Slaw',

      serving: 1,
      calories:40,
      fat:0.1,
      satFat: 0,
      carbohydrates: 9,
      fiber: 2,
      sugar: 4,
      protein:1,
      vc: 20,
      va: 0,
      vk: 0,
      calcium: 40,
      iron: 0.5,
      potassium: 300,

      minerals:['Vitamin D','Calcium'],

      category: '19He5XlTo9nMzI13ASNE',
      created_at: moment().format(),
      updated_at: moment().format()
    };

    this.afs.collection("products").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }






  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files?.[0];
   
    
    //this.previewImage(file);
    if (file) {
      // Generate a unique ID for the image file
    const fileId = Math.random().toString(36).substring(2);
    const filePath = `images/${fileId}-${file.name}`;
    const fileRef = this.storage.ref(filePath);

    // Upload the file to Firebase Storage
    const task = this.storage.upload(filePath, file);

    // Track the upload progress (optional)
    task.percentageChanges().subscribe(percentage => {
      console.log(`Upload is ${percentage}% done`);
    });

    // Handle the completion of the upload
    task.then(() => {
      console.log('File uploaded successfully');
      // Now you can store the image URL or path in Firestore, or perform other actions.
    });

    task.snapshotChanges().subscribe((snapshot) => {
      if (snapshot.state === 'success') {
        fileRef.getDownloadURL().subscribe((downloadURL) => {
          console.log('File available at', downloadURL);
          this.downloadURL = downloadURL;
          //this.formData.imageUrl = downloadURL;
          // You can save the downloadURL to your database or use it as needed.
        });
      }
    });


    this.previewImage(file);

    } else {
      this.clearPreview();
    }
  }

  clearCamera() {
    const stream = this.videoElementRef.nativeElement.srcObject as MediaStream;
    stream.getTracks().forEach(track => track.stop());
    this.videoElementRef.nativeElement.srcObject = null;
  }

  private previewImage(file: File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.previewImageUrl = e.target?.result;
      this.setPreviewContainerStyles(); // Set styles after the image is loaded
    };
    reader.readAsDataURL(file);
  }

  private setPreviewContainerStyles() {
    // Set styles for the preview container when there's an image
    this.previewContainerStyles = {
      'width': '300px', // Set your desired fixed width here
      'height': '200px', // Set your desired fixed height here
      'overflow': 'hidden',
      'border': '1px solid #ccc' // Optional: Add a border around the preview container
    };
  }

  clearPreview() {
    this.previewImageUrl = null;
    this.previewContainerStyles = {}; // Clear styles when there's no image
    //this.startCamera();
  }

  send(){
    
  }
  
}

