import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  state: any;
  
  categories : number;
  products : number;
  sales: number;
  admin: number;

  

  constructor(private afs: AngularFirestore, private router : Router) {
    this.state = this.router.getCurrentNavigation().extras.state;
    if(this.state && this.state.reload) window.location.reload();
  }

  ngOnInit() {

    this.afs.collection('sales').valueChanges().subscribe((res : any) => {
      this.sales = res.length;
    });

    this.afs.collection('products').valueChanges().subscribe((res : any) => {
      this.products = res.length;
    });

    this.afs.collection('categories').valueChanges().subscribe((res : any) => {
      this.categories = res.length;
    });
    
    this.afs.collection('users').valueChanges().subscribe((res : any) => {
      this.admin = res.length;
    });
  }

  

  

}


