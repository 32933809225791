import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CategoriesComponent } from './categories/racks.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductsComponent } from './products/products.component';
import { CoreModule } from 'src/app/core/core.module';
import { SalesComponent } from './sales/sales.component';
import { ChartTestComponent } from 'src/app/views/private/dashboard/chart-test/chart-test.component';
import { SettingsComponent } from './settings/settings.component';
import { GroupsComponent } from './groups/groups.component';
import { AdminManagementComponent } from './admin-management/admin-management.component';
import { ProductsDetailsComponent } from './products-details/products-details.component';
import { GeigerAdminComponent } from './geiger/geiger-admin.component';


const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'management',
    component: AdminManagementComponent
  },
  {
    path: 'categories',
    component: CategoriesComponent
  },
  {
    path: 'products',
    component: ProductsComponent
  },
  {
    path: 'details',
    component: ProductsDetailsComponent,
  },
  {
    path: 'sales',
    component: SalesComponent
  },
   {
     path: 'chart',
     component: ChartTestComponent,
   },
   
   {
    path: 'settings',
    component: SettingsComponent,
  },
   {
    path: 'groups',
    component: GroupsComponent,
  },
  {
    path: 'geiger',
    component: GeigerAdminComponent
  }
];


@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CoreModule,
    RouterModule.forChild(routes)
  ],
  exports: []
})
export class PrivateRoutingModule { }
