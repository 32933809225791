 <div class="row">
    
    <div class="card">      
    
        <div>
            <!--<p [ngClass]="{ 'sign-safe': isProductSafe, 'sign-unsafe': !isProductSafe }">{{ productStatusText }} {{sensor[2]}}  μSv/h</p>-->
            <p [ngClass]="{ 'sign-safe': isProductSafe, 'sign-unsafe': !isProductSafe }">{{ productStatusText }} {{fakeData}}  μSv/h</p>
        </div>
    <div #gaugeArea class="guage"></div>
    
    
    <button (click)="giveFakeData()" label="Start Reading" pButton type="button" class="p-button-sm"></button>
    <br>
    <button (click)="details()" label="Back" pButton type="button" class="p-button-sm"></button>
    <br>
    </div>
      
</div>

