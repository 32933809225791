import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldConfig, FormConfig } from 'ngx-nomad-form';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})

export class AdminsComponent implements OnInit {

  @Input() admin: any;
  @Input() display: boolean = false;
  @Output() call = new EventEmitter<any>();

  isAddForm: boolean = false;

  //My fields
  fields: FieldConfig[] = [];

  formConfig: FormConfig = {
    name: 'groupForm',
    enctype: 'text/plain',
  };

  constructor() { }

  ngOnInit(){
    
    this.isAddForm = this.admin?.email ? false : true;

    this.fields = [
      {
        type: 'input',
        label: 'Email',
        inputType: 'string',
        name: 'email',
        value: this.admin?.email,
        col: 12,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'email is required'
          },
          {
            name: 'email', // Use the email validator
            validator: Validators.email, // Angular's email validator
            message: 'invalid email format'
          }
        ]
      },
      {
        type: 'input',
        label: 'Password',
        inputType: 'string',
        name: 'password',
        value: this.admin?.password,
        col: 12,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message: 'password is required'
          },
          {
            name: 'minlength', // Add a validation for minimum length
            validator: Validators.minLength(6), // Adjust the minimum length as needed
            message: 'password must be at least 6 characters'
          }
        ]
      }, {
        type: 'button',
        color: 'primary',
        col: 12,
        label: this.admin.email ? 'Update' : 'Save'
      }
    ];
  }

  callBack(formData: any){
    console.log(formData);
    this.call.emit({
      'id' : this.admin?.id,
      'isAddForm' : this.isAddForm,
      ...formData
    });
  }

}
