// import { Component, OnInit, Input } from '@angular/core';
// import { BehaviorSubject, Observable } from 'rxjs';
// import { PopupService } from './popup.service';

// @Component({
//   selector: 'app-popup',
//   templateUrl: './popup.component.html',
//   styleUrls: ['./popup.component.scss']
// })
// export class PopupComponent implements OnInit {
  
//   sharedObject: any = { 
//     name: 'Initial name',
//     price: 'Initial price',
//     expiry: 'Expiry'
//    };

//   constructor(private dataService: PopupService) {}

//   ngOnInit() {
//     this.dataService.sharedObject$.subscribe((value) => {
//       this.sharedObject = value;
//     });
//   }
// }

import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PopupService } from './popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})

export class PopupComponent implements OnInit {
  constructor(private dataService: PopupService) {}

    sharedObject: any = { 
    
   };
   recommendedRecipes={}

    ngOnInit() {
    this.dataService.sharedObject$.subscribe((value) => {
      this.sharedObject = value;
      this.recommendedRecipes = [
        { name:  this.sharedObject.recipe1name, pictureUrl: this.sharedObject.recipe1, ingredients: this.sharedObject.recipe1items },
        { name:   this.sharedObject.recipe2name, pictureUrl: this.sharedObject.recipe2, ingredients: this.sharedObject.recipe2items },
        { name:  this.sharedObject.recipe3name, pictureUrl: this.sharedObject.recipe3, ingredients: this.sharedObject.recipe3items }
      ];
      
    });
  }

  // Sample data for recommended recipes
  

  selectedRecipe: any = null;

  showRecipePopup(recipe: any) {
    this.selectedRecipe = recipe;
  }

  closePopup() {
    this.selectedRecipe = null;
  }
}

