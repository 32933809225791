import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Validators } from '@angular/forms';
import { FieldConfig, FormConfig } from 'ngx-nomad-form';
import { AngularFireStorage } from '@angular/fire/compat/storage'; 

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() product: any;
  @Input() display: boolean = false;
  @Output() call = new EventEmitter<any>();

  categories: any[] = [];
  groups: any[] = [];
  rows: any[] = [];
  rowsExact: any[] =[];
  columns: any[] = [];
  columnsExact: any[] = [];
  x:any;
  y:any;
  reserve: any;
  expiryField: any;
  expiryFieldEmpty: any;
  public previewImageUrl: string | ArrayBuffer | null = null;
  public previewContainerStyles: { [key: string]: string } = {};


  isAddForm: boolean = false;

  //My fields
  fields: FieldConfig[] = [];

  formConfig: FormConfig = {
    name: 'productForm',
    enctype: 'text/plain',
  };

  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) {}

  ngOnInit(){
    
    this.afs.collection('categories').valueChanges().subscribe((res : any) => {
      this.categories = [];
      this.rows = [];
      this.columns = [];

      res.forEach((element) => {
       this.categories.push({
         'label': element.name,
         'value': element.id
        });
      });

      
       
       
       for (let i=0; i< res[0].row; i++){
        this.rows.push({
          'label': i+1,
          'value': i+1
         })
       }

       for (let i=0; i< res[0].column; i++){
        this.columns.push({
          'label': i+1,
          'value': i+1
         })
       }
       
    });

    this.afs.collection('groups').valueChanges().subscribe((res : any) => {
      this.groups = [];
      
      res.forEach((element) => {
       this.groups.push({
         'label': element.name,
         'value': element.id
        });
      });

      for(let i=0; i<this.groups.length;i++){
        if(this.groups[i].value=='lUm00KQUdhoaVs7kLDEW'){
          this.reserve=this.groups[i];
          this.groups.splice(i,1);
        }
      }
      
      this.reserve.value='';
      this.groups.push(this.reserve)
      

    });

    

    this.x={
      type: 'input',
      label: 'Register Type Name',
      inputType: 'text',
      name: 'group',
      col: 12,
      value: 'this.product?.group',
      validations: [{
        name: 'required',
        validator: Validators.required,
        message: 'Product type name is required'
      }]
    }

    this.expiryField={
      type: 'input',
      label: 'Expiry Date',
      inputType: 'Date',
      name: 'expiry',
      value: this.product?.expiry,
      col: 12,
      validations: [{
        name: 'required',
        validator: Validators.required,
        message: 'expiry is required'
      }]
    }

    this.expiryFieldEmpty={
      type: 'select',
      label: 'Expiry Date',
      name: 'expiry',
      col: 12,
      value: 'Auto',
      options: [{
        'label': 'Automatically Assigned',
        'value': 'auto',
       }],
      validations: [{
        name: 'required',
        validator: Validators.required,
        message: 'expiry is required'
      }]
    }

    this.isAddForm = this.product?.name ? false : true;

    interface ButtonFieldConfig {
      type: 'button';
      color: string;
      label: string;
      col: number;
      onClick?: () => void; // Define onClick as an optional property
    }

    this.fields = [
      {
        type: 'input',
        label: 'Name',
        inputType: 'text',
        name: 'name',
        col: 12,
        value: this.product?.name,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Name is required'
        }]
      },
      {
        type: 'input',
        label: 'Price',
        inputType: 'number',
        name: 'price',
        value: this.product?.price,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'price is required'
        }]
      },{
        type: 'select',
        label: 'Rack',
        name: 'category',
        value: this.product?.category,
        col: 12,
        options: []
      },{
        type: 'select',
        label: 'Cell Location',
        inputType: 'number',
        name: 'column',
        value: this.product?.column,
        col: 12,
        options: []
      },
      {
        type: 'select',
        label: 'Expiry Format',
        name: 'auto',
        col: 12,
        value: 'Custom Expiry Assignment',
        options: [{
          'label': 'Custom Expiry Assignment',
          'value': 'Custom Expiry Assignment'
         },{
          'label': 'Auto Expiry Assignment',
          'value': 'Auto Expiry Assignment',
         }]
      },
      {
        type: 'input',
        label: 'Expiry Date',
        inputType: 'Date',
        name: 'expiry',
        value: this.product?.expiry,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'expiry is required'
        }]
      },
     /*{
        type: 'input',
        label: 'Product Image',
        inputType: 'text',
        name: 'imageUrl',
        col: 12,
        value: this.product?.imageUrl,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'ImageUrl is required'
        }]
      },*/
      
      {
        type: 'select',
        label: 'Product Type',
        name: 'group',
        value: this.product?.group,
        col: 12,
        options: []
      }, 
      {
        type: 'button',
        color: 'primary',
        label: this.product.name ? 'Update' : 'Save',
        col: 12
      }
    ];
    
    setTimeout(() => {
      this.fields[6].options = this.groups;
      this.fields[6].value = this.product?.group;

      this.fields[2].options = this.rows;
      this.fields[2].value = this.product?.row;
      this.fields[3].options = this.columns;
      this.fields[3].value = this.product?.column;

      this.fields[2].options = this.categories;
      this.fields[2].value = this.product?.category;

    }, 2000);
  }

  callBack(formData: any){
    console.log(formData);
    this.call.emit({
      'id' : this.product?.id,
      'isAddForm' : this.isAddForm,
      ...formData
    });
  }

  
  change(value: any){

    console.log(value);

    if(value.group==''){
      this.fields.splice(7,1,this.x);
    }
    
    if(value.auto=='Auto Expiry Assignment'){
      this.fields.splice(5,1,this.expiryFieldEmpty);
    }else if(value.auto=='Custom Expiry Assignment'){
      this.fields.splice(5,1,this.expiryField);
    }
    
  }

 


}

       //must be rows length not res length
       //also consider each showcase can have different rows and columns
       
       /*
       for (let i=1; i< (res.length+1); i++){
        this.rows.push({
          'label': i,
          'value': i
         })
       }

       for (let i=1; i< (res.length+1); i++){
        this.columns.push({
          'label': i,
          'value': i
         })
       }*/


/*{
        type: 'button',
        
        color: 'primary',
        label: 'Add photo',
        col: 6
      },*/



/*,{
        type: 'select',
        label: 'Row',
        inputType: 'number',
        name: 'row',
        value: this.product?.row,
        col: 6,
        options: []
      }*/

//this.fields[3].options = this.rows;
    //this.fields[3].value = this.product?.row;

/*
      res.forEach((element) => {
        this.rows.push({
          'label': element.name,
          'value': element.row
         });
       });

       res.forEach((element) => {
        this.columns.push({
          'label': element.name,
          'value': element.column
         });
       });*/

       //console.log(res[0].row)
/*
    setTimeout(() => {
      this.fields[2].options = this.groups;
      this.fields[2].value = this.product?.group;
    }, 2000);*/

/*{
        type: 'select',
        label: 'Status',
        name: 'status',
        value: this.product?.status ? 'enable' : 'disable',
        col: 12,
        options: [
          {
            label: 'Enable',
            value: true
          },{
            label: 'Disable',
            value: false
          }
        ]
      },
      {
        type: 'checkbox',
        label: 'Accept Terms',
        name: 'term',
        col: 12,
        value: true
      },*/

      /*{
        type: 'checkbox',
        label: ' New Product Type',
        name: 'term',
        col: 12,
        value: false
      },*/

      /*this.y={
        type: 'select',
        label: 'Product Type',
        name: 'group',
        value: this.product?.group,
        col: 12,
        options: this.groups
      };*/  

/*
  change(value: any){

    //console.log(value.term);
    //this.temp=[];
    //console.log(value)

    if(value.group=='lUm00KQUdhoaVs7kLDEW' && this.fields.length==8){
      //console.log('pass')
      this.fields.splice(7,0,this.x);
      //this.fields.push(this.x);
    }else{
      //console.log(this.fields.length)
      if(value.group!='lUm00KQUdhoaVs7kLDEW' && this.fields.length==9){
        this.fields.splice(7,1);
      }
      //this.fields.splice(1,1,this.y);
      //this.fields.pop();
    }
    
  }
  
  ,{
        type: 'input',
        label: 'Location ID',
        inputType: 'number',
        name: 'location',
        value: this.product?.location,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'location is required'
        }]
      }*/
      