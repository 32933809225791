import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private ws: WebSocket;

  constructor() {
    this.connect();
  }

  // Connect to the WebSocket server
  private connect(): void {
    this.ws = new WebSocket('wss://superb-drum-selected.ngrok-free.app');
    
    // Event listener for WebSocket connection open
    this.ws.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    // Event listener for receiving messages
    this.ws.onmessage = (event) => {
      console.log('Message from server: ', event.data);
    };

    // Event listener for WebSocket error
    this.ws.onerror = (error) => {
      console.error('WebSocket error: ', error);
    };

    // Event listener for WebSocket connection close
    this.ws.onclose = () => {
      console.log('WebSocket connection closed');
    };
  }

  // Send message to WebSocket server

  mapNumber(inputNumber: number): number {
    const mapping = {
      41: 1,  42: 2,  43: 3,  44: 4,
      31: 5,  32: 6,  33: 7,  34: 8,
      21: 9,  22: 10, 23: 11, 24: 12,
      11: 13, 12: 14, 13: 15, 14: 16
    };
  
    return mapping[inputNumber] ;
  }
  
  public sendMessageEach(currentLocation: number): void {

    currentLocation= this.mapNumber(currentLocation);
    const message = {
      valueS: currentLocation,
      //count: 1,
    };

    // Convert message to JSON string and send it through WebSocket
    this.ws.send(JSON.stringify(message));
  }
}
