import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Validators } from '@angular/forms';
import { FieldConfig, FormConfig } from 'ngx-nomad-form';
import { AngularFireStorage } from '@angular/fire/compat/storage'; 

@Component({
  selector: 'app-geiger-form',
  templateUrl: './geiger-form.component.html',
  styleUrls: ['./geiger-form.component.scss']
})

export class GeigerFormComponent implements OnInit {

  @Input() geiger: any;
  @Input() display: boolean = false;
  @Output() call = new EventEmitter<any>();

  categories: any[] = [];
  groups: any[] = [];
  rows: any[] = [];
  rowsExact: any[] =[];
  columns: any[] = [];
  columnsExact: any[] = [];
  x:any;
  y:any;
  reserve: any;
  expiryField: any;
  expiryFieldEmpty: any;
  public previewImageUrl: string | ArrayBuffer | null = null;
  public previewContainerStyles: { [key: string]: string } = {};


  isAddForm: boolean = false;

  //My fields
  fields: FieldConfig[] = [];

  formConfig: FormConfig = {
    name: 'geigerForm',
    enctype: 'text/plain',
  };

  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) {}

  ngOnInit(){


    this.isAddForm = this.geiger?.name ? false : true;

    interface ButtonFieldConfig {
      type: 'button';
      color: string;
      label: string;
      col: number;
      onClick?: () => void; // Define onClick as an optional property
    }

    this.fields = [
      {
        type: 'input',
        label: 'Name',
        inputType: 'text',
        name: 'name',
        col: 12,
        value: this.geiger?.name,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Name is required'
        }]
      },
      {
        type: 'input',
        label: 'Entry Radiation',
        inputType: 'number',
        name: 'entry',
        value: this.geiger?.entry,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Entry radiation is required'
        }]
      },{
        type: 'input',
        label: 'Safe Radiation',
        inputType: 'number',
        name: 'test',
        value: this.geiger?.test,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Safe radiation is required'
        }]
      },
      {
        type: 'button',
        color: 'primary',
        label: this.geiger.name ? 'Update' : 'Save',
        col: 12
      }
    ];

  }

  callBack(formData: any){
    console.log(formData);
    this.call.emit({
      'id' : this.geiger?.id,
      'isAddForm' : this.isAddForm,
      ...formData
    });
  }
  
  change(value: any){

    console.log(value);
    
  }

 


}