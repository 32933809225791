import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartOptions, ChartType } from "chart.js";
import { Category } from 'src/app/core/models';
import { FirebaseService, NotifyService } from 'src/app/core/services';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Product, Sale } from 'src/app/core/models';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Component({
  selector: 'app-chart-test',
  templateUrl: './chart-test.component.html',
  styleUrls: ['./chart-test.component.scss']
})
export class ChartTestComponent implements OnInit {

  categories : Category[] = [];
  products : Product[] = [];

  capacity : number[] = [];
  showcaseArr: any = [];
  count: any = 0;
  countArr: any=[];

  temp : any ;
  sales : Sale[] = [];
  dates : any =[];
  datesFormat: any=[];
  totalSales: number= 0;
  totalSalesArr: any = [];
  totalSalesCount: number=0;
  datesArr:any=[];

  sensor:any;


  constructor(private firestore : FirebaseService, public notify : NotifyService, 
    private afs: AngularFirestore, private db: AngularFireDatabase) { }
  

  ngOnInit() {
      this.fetchProducts();
      this.fetchSales();
      this.getTest();
    }

  getTest(){
    this.sensor=this.db.list('/test').valueChanges().subscribe((res:any) => { 
      this.sensor = res
      //console.log(this.sensor)
      //console.log(this.sensor);
    });
  }
  
  fetchProducts() {

    this.afs.collection('categories', ref => ref.orderBy('name')).valueChanges().subscribe((res : any) => {
      this.categories = res;
      
      this.capacity=[]
      this.categories.forEach(x=>this.capacity.push(x.capacity));
      
      this.showcaseArr=[]
      this.showcaseArr = Array.from(Array(this.categories.length).keys()).map(x => x + 1); //showcase array 1,2,3 assign
      //console.log(this.showcaseArr)

    this.afs.collection('products').valueChanges().subscribe((res : any) => {
      this.products = res;

      this.countArr=[]
      for (let i = 0; i < this.categories.length; i++) {
        this.multiply(i);
      }
      
    });
    });
  }

  checkString(x:any,y:any){ //check two key strings for showcase and products
    if (x===y){
      return 1
    }else{
      return 0
    }
  }

  multiply(y:any){ //product counting for each showcase
    this.products.forEach( x=> this.count= this.count+this.checkString(x.category,this.categories[y].id) )
    this.countArr.push(this.count);
    this.count=0;
  }

  fetchSales() {

    this.afs.collection('sales').valueChanges().subscribe((res : any) => {
      this.sales = res;

      this.totalSales=0;

      this.sales.forEach( x=> this.totalSales = this.totalSales + x.amount )

      this.dates=[]

      for (let i = 0; i < this.sales.length; i++) {
        this.dates.push([this.sales[i].created_at,this.sales[i].amount]);
        
      }

      this.datesFormat=[]

      for (let i = 0; i < this.dates.length; i++) {
        this.datesFormat.push([this.dates[i][0].substring(0,10),this.dates[i][1]]);
      }

      this.datesArr=[]

      for (let i = 0; i < this.datesFormat.length; i++) {
        this.datesArr.push(this.datesFormat[i][0]);
      }

      this.datesArr=this.datesArr.sort();
      this.datesArr=[...new Set(this.datesArr)];

      this.totalSalesCount=0;
      this.datesFormat=this.datesFormat.sort();
      this.totalSalesArr=[]
      
      while(this.datesFormat.length>0){
        this.temp = this.datesFormat[0][0];
        for (let j = 0; j < this.datesFormat.length; j++) {

          if(this.temp == this.datesFormat[j][0]){ // has to restart from 0 index
            this.totalSalesCount= this.totalSalesCount+ this.datesFormat[j][1];
            this.datesFormat.splice(j,1);//remove item after adding
            j=j-1;
          }
        }
        this.totalSalesArr.push(this.totalSalesCount);
        this.totalSalesCount=0;
      }
      
      //console.log(this.totalSalesArr);

    });
  }
  
  public barChartLegend = true;
  public barChartPlugins = [];
  
  /*public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Showcase Inventory',
        font: {
          size: 17
        }
      }
      
    },
    scales: {
      y: {
        ticks: {
            font: {
              size: 14
            }
        }},
      x: {
          ticks: {
              font: {
                  size: 14,
              }
          }
      }
      }
    
  };*/

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Showcase Inventory',
        font: {
          size: 17
        }
      }
    },
    scales: {
      y: {
        ticks: {
          font: {
            size: 14
          }
        }
      },
      x: {
        ticks: {
          font: {
            size: 14,
          },
          callback: (value: any, index: number, values: any) => {
            // Convert the numeric values (1, 2, 3) to "rack 1," "rack 2," "rack 3"
            return `Rack ${value+1}`;
          }
        }
      }
    }
  };
  

  public barChartLegend2 = true;
  public barChartPlugins2 = [];

  
  public barChartOptions2: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Sales Statistics',
        font: {
          size: 17
        }
      }
    },
    scales: {
      y: {
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {

                return new Intl.NumberFormat('en-US',{
                  style: 'currency',
                  currency: 'KRW',
                  maximumSignificantDigits:3
                }).format(Number(value));
            },
            font: {
              size: 14
            }
        }},
      x: {
          ticks: {
              font: {
                  size: 14,
              }
          }
      }
      }
    
  };

}

/*

public lineChartData: ChartConfiguration<'line'>['data'] = { 
    
    labels: [
      '8-1-2022',
      '8-8-2022',
      '8-15-2022',
      '8-22-2022'
    ],
    datasets: [
      {
        data: [ 1, 2, 3, 1 ],
        label: 'Showcase 1',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)'
      },
      {
        data: [ 5, 6, 4, 3 ],
        label: 'Showcase 2',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(0, 255, 0, 0.3)'
      },
      {
        data: [ 10, 9, 11, 12 ],
        label: 'Showcase 3',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(0, 0, 255, 0.3)'
      }
    ]
  };

  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Temperature Data'
    }},
    scales: {
      y: {
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
                return value + '°C';
            }
        }}}
  };

  public lineChartLegend = true;

  public lineChartData2: ChartConfiguration<'line'>['data'] = {
    
    labels: [
      '8-1-2022',
      '8-8-2022',
      '8-15-2022',
      '8-22-2022',
      '8-29-2022',
      '9-5-2022',
      '9-12-2022',
      '9-19-2022'
    ],
    datasets: [
      {
        data: [ 1, 2, 3, 1, 1, 2, 3, 1 ],
        label: 'Showcase 1',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)'
      },
      {
        data: [ 5, 6, 4, 3, 5, 6, 4, 3 ],
        label: 'Showcase 2',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(0, 255, 0, 0.3)'
      },
      {
        data: [ 10, 9, 11, 12, 10, 9, 11, 12 ],
        label: 'Showcase 3',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(0, 0, 255, 0.3)'
      },
      {
        data: [ 16, 17, 18, 16, 16, 15, 18, 15 ],
        label: 'Total',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(0, 255, 0, 0.3)'
      }
    ]
  };

  public lineChart2Options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Sales Statistics'
    }},
    scales: {
      y: {
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, ticks) {
                return '₩ ' + value + 'M';
            }
        }}}
  };

  public lineChart2Legend = true;

  title2 = 'Inventory';

  public barChartData : ChartConfiguration<'bar'>['data'] = {
    labels: [ 'Showcase 1', 'Showcase 2', 'Showcase 3' ],
    datasets: [
      { data: [this.capacity[0],this.capacity[1],this.capacity[2]], label: 'Capacity' },
      { data: [ 19, 13, 10 ], label: 'Current Stock' }
    ]
  };

fetch() {
    this.afs.collection('categories', ref => ref.orderBy('name')).valueChanges().subscribe((res : any) => {
      this.categories = res;  
      this.categories.forEach(x=>this.temp.push(x.capacity));
      
      this.showcase = this.categories.length; //total showcases
      this.showcaseArr = Array.from(Array(this.showcase).keys()).map(x => x + 1); //showcase array 1,2,3 assign
      this.capacity= this.temp;

      //console.log(this.categories[0].id);
    });
  }*/

