<div class="col-md-12">

    <div class="card  cp">
      <div class="card-body">
  
        <div class="row">
          <div class="col-md-6 text-start">
            <!--<h2 class="mt-2">Product Types</h2>-->
            
            <p class="title text-black">Inventory</p>
          </div>
          <div class="col-md-6 text-right">

            <!--<button (click)="refresh()" pButton type="button" icon="pi pi-refresh" class="p-button-sm p-button-link mr-2"></button>-->
            <!--<button (click)="add()" label="Add new type" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>-->

            <button (click)="details()" label="Details" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>

           
          </div>
    </div>
  
    <p-table dataKey="id" stateKey="products" [value]="allProductsName" styleClass="p-datatable-striped" [rows]="10"
        [paginator]="true" #yp1 lang="fr">
        
        <ng-template pTemplate="header">
          <tr>
            <th class="text-left">Name</th>
            <th class="text-right">Total Stock</th>
            
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr>
            <td class="text-left">{{ product.name }}</td>
            <td class="text-right">{{ countName(product.name) }}</td>
            
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" class="d-none">
          <tr>
            <td colspan="7">No products found.</td>
          </tr>
        </ng-template>
      </p-table>
  
    </div>
  </div>
    
  </div>
    
    <p-dialog *ngIf="display" [header]="group.name ? 'Group: ' + group.name : 'Add new product type'"  [resizable]="true" [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="display" [style]="{width: '50vw'}">
      <app-group [display]="display" [group]="group" (call)="saverG($event)"></app-group>
    </p-dialog>
    