import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Validators } from '@angular/forms';
import { FieldConfig, FormConfig } from 'ngx-nomad-form';
import { AngularFireStorage } from '@angular/fire/compat/storage'; 

@Component({
  selector: 'app-scan-barcode',
  templateUrl: './scan-barcode.component.html',
  styleUrls: ['./scan-barcode.component.scss']
})
export class ScanBarcodeComponent implements OnInit {

  @Input() product: any;
  @Input() display: boolean = false;
  @Output() call = new EventEmitter<any>();

  categories: any[] = [];
  groups: any[] = [];
  rows: any[] = [];
  rowsExact: any[] =[];
  columns: any[] = [];
  columnsExact: any[] = [];
  x:any;
  y:any;
  reserve: any;
  expiryField: any;
  expiryFieldEmpty: any;
  isAddForm: boolean = false;

  //My fields
  fields: FieldConfig[] = [];

  formConfig: FormConfig = {
    name: 'productForm',
    enctype: 'text/plain',
  };

  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) {}

  ngOnInit(){
    
    this.afs.collection('categories').valueChanges().subscribe((res : any) => {
      this.categories = [];
      this.rows = [];
      this.columns = [];

      res.forEach((element) => {
       this.categories.push({
         'label': element.name,
         'value': element.id
        });
      });

      
       
       
       for (let i=0; i< res[0].row; i++){
        this.rows.push({
          'label': i+1,
          'value': i+1
         })
       }

       for (let i=0; i< res[0].column; i++){
        this.columns.push({
          'label': i+1,
          'value': i+1
         })
       }
       
    });

    this.afs.collection('groups').valueChanges().subscribe((res : any) => {
      this.groups = [];
      
      res.forEach((element) => {
       this.groups.push({
         'label': element.name,
         'value': element.id
        });
      });

      for(let i=0; i<this.groups.length;i++){
        if(this.groups[i].value=='lUm00KQUdhoaVs7kLDEW'){
          this.reserve=this.groups[i];
          this.groups.splice(i,1);
        }
      }
      
      this.reserve.value='';
      this.groups.push(this.reserve)
      

    });
   
   
    this.isAddForm = this.product?.name ? false : true;

    interface ButtonFieldConfig {
      type: 'button';
      color: string;
      label: string;
      col: number;
      onClick?: () => void; // Define onClick as an optional property
    }

    this.fields = [
      {
        type: 'input',
        label: 'Name',
        inputType: 'text',
        name: 'name',
        col: 12,
        value: this.product?.name,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Name is required'
        }]
      },
      {
        type: 'button',
        color: 'primary',
        label: 'Save',
        col: 12
      }
    ];
    
   
  }

  callBack(formData: any){
    console.log(formData);
    this.call.emit(
      
      //'isAddForm' : this.isAddForm,
      formData
    );
  }

  
  change(value: any){

    console.log(value);

    
  }

}



