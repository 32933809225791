import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core'; // Import ViewChild and ElementRef
import * as GaugeChart from 'gauge-chart'; // Import the gauge-chart library
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirebaseService, NotifyService } from 'src/app/core/services';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-geiger',
  templateUrl: './geiger-admin.component.html',
  styleUrls: ['./geiger-admin.component.scss'],
})
export class GeigerAdminComponent implements OnInit {
  @ViewChild('gaugeArea', { static: true }) gaugeArea!: ElementRef;
  isProductSafe: boolean;
  value: number = 20;
  sensor:any;
  productStatusText: string;
  categories:any;
  geiger:any;
  display:boolean= false;
  
  wholeData:any = 10;
  //wholeData:any = 14;

  //wholeData:any = 2.6; max 0.26 microsieverts per hr.

  percentage: any;

  groups:any= 
  [
  { 
    name: '고등어',
    geigerEntry: 25,
    geiger: 20
  },
  { 
    name: '참치',
    geigerEntry: 20,
    geiger: 30
  },
  { 
    name: '노래미',
    geigerEntry: 10,
    geiger: 10
  },
  { 
    name: '장어',
    geigerEntry: 10,
    geiger: 15
  },
  { 
    name: '삼치',
    geigerEntry: 15,
    geiger: 20
  },
  { 
    name: '멸치',
    geigerEntry: 30,
    geiger: 50
  }
  ]
   
  constructor(public router:Router, private db:AngularFireDatabase, private afs:AngularFirestore, private firestore:FirebaseService, public notify:NotifyService) {
    
  }

  ngOnInit(): void {
    this.draw(this.value);
    this.getTest();
    this.fetch();
  }

  gaugeOptions = {
    hasNeedle: true,
    needleColor: 'black',
    arcPadding: '10',
    needleUpdateSpeed: 1000,
    arcColors: ['#558b2f', '#F7DC6F', '#C0392B'], 
    //arcColors: ['#558b2f', '#C0392B'], 
    //arcDelimiters: [30, 60], 
    //arcDelimiters: [28.57,71.425], 
    arcDelimiters: [40,70], // Adjust arcDelimiters as needed
    rangeLabel: ['0', '1'],
    centralLabel: 'μSv/h',
  };

  getTest(){
    this.sensor=this.db.list('/serialData').valueChanges().subscribe((res:any) => { 
      this.sensor = res
      if(this.sensor[2]<0.4){
        this.isProductSafe = true;
        this.productStatusText  = 'PRODUCT SAFE: ';
      }else{
        this.isProductSafe = false;
        this.productStatusText  = 'PRODUCT UNSAFE: '
      }
      
      console.log(this.sensor)

      this.percentage = (this.sensor[2] / this.wholeData) * 1000;
      console.log(this.percentage)

      if (this.gaugeArea.nativeElement.firstChild) {
        this.gaugeArea.nativeElement.removeChild(this.gaugeArea.nativeElement.firstChild);
      }

      this.draw(this.percentage)

    });
  }

  fetch() {
    this.afs.collection('geiger').valueChanges().subscribe((res : any) => {
      this.geiger = res;
      console.log(this.geiger[0]);
    });
  }

  
  add(){
    this.display = true;
  }

  edit(data:any){

  }

  delete(id: string){
    Swal.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        this.firestore.deleteDocument('geiger', id).then(val => {
          Swal.fire(
            'Deleted!',
            'This product has been deleted.',
            'success'
          );
          this.fetch();
        }, err => {
          this.notify.error2("No product exist");
        });
      }
    })
  }
  
  draw(data: number) {
    GaugeChart.gaugeChart(
      this.gaugeArea.nativeElement,
      500,
      this.gaugeOptions
    ).updateNeedle(data);
  }

  details(){
    this.router.navigate(['pos']);
  }
  
  saver(formData: any){
    if(!formData.isAddForm) {
      this.updateProduct(formData);
    } else {
      this.addProduct(formData);
      
    }
    this.display = false;
    this.geiger = [];
  }

  updateProduct(formData: any){

    let data = {
      name: formData.name,
      entry: formData.entry,
      test: formData.test,
     
      category: formData.category,
      updated_at: moment().format()
    };

    console.log(data);

    this.afs.doc<any>(`geiger/${formData.id}`).update(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }

  addProduct(formData: any){


    let data = {
      id: this.afs.createId(),
      name: formData.name,
      entry: formData.entry,
      test: formData.test,

      created_at: moment().format(),
      updated_at: moment().format()
    };

    console.log(formData)

    this.afs.collection("geiger").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("No valid product");
    });
  }





}


 /*this.gaugeChart = GaugeChart.gaugeChart(
        this.gaugeArea.nativeElement, // Use the nativeElement of ViewChild to access the DOM element
        500,
        this.gaugeOptions
      ).updateNeedle(percentage);
      console.log(this.sensor[2])*/ 