<!--<div class="col-md-12">
    <p-table dataKey="id" stateKey="categories" [value]="discounts" styleClass="p-datatable-striped" [rows]="10" #yp1 lang="fr">
      <ng-template pTemplate="caption">
        <div class="row">
        
          <div class="col-md-6 text-start">
            <h2 class="mt-2">Seasonal Time-based Discount</h2>
          </div>
          <div class="col-md-6 text-right">
            <button (click)="add()" label="Set Discount" pButton type="button" class="p-button-sm"></button>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="expiry">
            Starting Expiry Time
            
          </th>
          <th class="text-right" pSortableColumn="percentage">Percentage
          </th>
          <th class="text-right" pSortableColumn="season">Season
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-discount>
        <tr>
          <td>{{ discount.expiry }} hours</td>
          <td class="text-right">{{ discount.percentage }}% + 10% for each hour to expiry</td>
          <td class="text-right">{{ discount.season }}</td>
          <td class="text-right">
            <button (click)="delete(discount.id)" pButton type="button" icon="pi pi-trash" class="p-button-sm p-button-danger"></button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" class="d-none">
        <tr>
          <td colspan="3">No discount rule found.</td>
        </tr>
      </ng-template>
      
    </p-table>
  
  </div>-->
  
  <!--
  <p-dialog *ngIf="display" [header]="discount.name ? 'Discount: ' + discount.name : 'Set Discount Rule'"  [resizable]="true" [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="display" styleClass="custom">
    <app-setting [display]="display" [discount]="discount" (call)="saver($event)"></app-setting>
  </p-dialog>

  <div class="col-md-12">

    <div class="card  cp">
      <div class="card-body">
        <div class="row"> 
          <div class="col-md-6 text-start">
            <p class="title text-black">Inventory Warning</p>
          </div>
          <div class="col-md-6 text-right">
            <button (click)="add()" label="Set Warning" pButton type="button" class="p-button-sm"></button>
          </div>
        </div>
    
        
        
        <p-table dataKey="id" stateKey="categories" [value]="promotions" class="some" styleClass="p-datatable-striped" [rows]="10" #yp1 lang="fr">
          
    
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="expiry">
                Rule 
              </th>
              <th class="text-center" pSortableColumn="expiry">
                Stock 
              </th>
              
              
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body">
            <tr>
              <td><span class="p-column-title">Rule :</span>When total stock is less than or equals to</td>
              <td class="text-center"><span class="p-column-title">Stock :</span>10</td>
              
              
              <td class="text-right">
                <button (click)="delete(discount.id)" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>
              </td>
            </tr>
          </ng-template>
    
          <ng-template pTemplate="emptymessage" class="d-none">
            <tr>
              <td colspan="3">No discount rule found.</td>
            </tr>
          </ng-template>
          
        </p-table>
      
      </div>
    </div>

    
  </div>-->

  <!--
  <ng-template pTemplate="caption">
    <div class="row"> 
      
    </div>
  </ng-template>
  -->
  

  <!--<button (click)="edit(discount)" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>-->

  <!--
     <p-dialog *ngIf="display" [header]="discount.name ? 'Discount: ' + discount.name : 'Set Discount Rule'"  [resizable]="true" [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="display" [style]="{width: '50vw'}">
    <app-setting [display]="display" [discount]="discount" (call)="saver($event)"></app-setting>
  </p-dialog>
  -->
  
  <!--<nf-form [fields]="fields" [formConfig]="formConfig" (callBack)="callBack($event)"></nf-form>-->

  <p-dialog *ngIf="display" [header]="discount.name ? 'Discount: ' + discount.name : 'Change Password'"  [resizable]="true" [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="display" styleClass="custom">
    <app-setting [display]="display" [discount]="discount" (call)="saver($event)"></app-setting>
  </p-dialog>

  <div class="col-md-12">

    <div class="card  cp">
      <div class="card-body">

      <p class="title text-black">Settings</p>
          
        <div class="card  cp">
          <div class="card-body">
            <div class="row"> 
              <div class="col-md-6 text-start">
                <p class="text">Change Password</p>
              </div>
              <div class="col-md-6 text-right">
                <button (click)="add()" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>
              </div>
            </div>
          </div>
        </div>

        <div class="card  cp">
          <div class="card-body">
            <div class="row"> 
              <div class="col-md-6 text-start">
                <p class="text">Inventory Warning</p>
              </div>
              <div class="col-md-6 text-right">
                <button (click)="add()" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>
              </div>
            </div>
          </div>
        </div>

        <div class="card  cp">
          <div class="card-body">
            <div class="row"> 
              <div class="col-md-6 text-start">
                <p class="text">Sales Countdown</p>
              </div>
              <div class="col-md-6 text-right">
                <button (click)="add()" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>
              </div>
            </div>
          </div>
        </div>
        
        

      </div>
    </div>

  </div>