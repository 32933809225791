<div class="sidebar">

  <p style="padding-top:10px; padding-left:20px; font-size:18px">Showcase Inventory Management</p>

  <a (click)="dashboard()" style="cursor: pointer;" aria-expanded="false">
    <span class="nav-text">Dashboard</span>
  </a>

  <a (click)="management()" style="cursor: pointer;" aria-expanded="false">
    <span class="nav-text">Manage Admins</span>
  </a>

  <a (click)="categories()" style="cursor: pointer;" aria-expanded="false">
    <span class="nav-text">Rack</span>
  </a>

  <a (click)="groups()" style="cursor: pointer;" aria-expanded="false">
    <span class="nav-text">Product Types</span>
  </a>

  <a (click)="products()" style="cursor: pointer;" aria-expanded="false">
    <span class="nav-text">Inventory</span>
  </a>

  <a (click)="geiger()" style="cursor: pointer;" aria-expanded="false">
    <span class="nav-text">Check Radiation</span>
  </a>

  <a (click)="sales()" style="cursor: pointer;" aria-expanded="false">
    <span class="nav-text">Sales</span>
  </a>

  <!--<a (click)="settings()" style="cursor: pointer;" aria-expanded="false">
    <span class="nav-text">Settings</span>
  </a>-->

  <a (click)="pos()" style="cursor: pointer;" aria-expanded="false">
    <span class="nav-text">POS</span>
  </a>

  <a (click)="logOut()" style="cursor: pointer;" aria-expanded="false">
    <span class="nav-text">Logout</span>
  </a>

  
</div>

<div class="card  cp">
  <div class="card-body">
    <a>
  <input id="darkMode" type="checkbox" [checked]="darkMode$ | async" (change)="onToggle()" class="toggle"/>
  <label class="toggle-label" for="darkMode">
  <img
    src="https://raw.githubusercontent.com/talohana/angular-dark-mode/master/src/assets/moon.svg"
    class="moon"
  />
  <img
    src="https://raw.githubusercontent.com/talohana/angular-dark-mode/master/src/assets/sun.svg"
    class="sun"
  />
  </label>
  </a>
  </div>
</div>

