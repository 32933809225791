<div class="header">
  <div class="header-content">
    <nav class="navbar navbar-expand">
      <div class="collapse navbar-collapse justify-content-between">
        <div class="header-left">
        </div>

        <ul class="navbar-nav header-right">
          <li class="nav-item header-profile">
            <a class="nav-link" role="button">
              <div class="header-info text-right mr-2">
                <small>Admin</small>
              </div>

            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
