import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { Category } from 'src/app/core/models';
import { FirebaseService, NotifyService } from 'src/app/core/services';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-racks',
  templateUrl: './racks.component.html',
  styleUrls: ['./racks.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories : Category[] = [];
  display: boolean = false;
  category : any;

  constructor(private firestore : FirebaseService, public notify : NotifyService, 
    private afs: AngularFirestore) { }

  ngOnInit() {
    this.fetch();
  }

  add(){
    this.category = [];
    this.display= true;
  }

  refresh(){
    this.fetch();
  }

  fetch() {
    this.afs.collection('categories').valueChanges().subscribe((res : any) => {
      this.categories = res;
      //console.log(this.categories);
    });
  }

  delete(id: string){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.firestore.deleteDocument('categories', id).then(val => {
          Swal.fire(
            'Deleted!',
            'This category has been deleted.',
            'success'
          );
          this.fetch();
        }, err => {
          this.notify.error2("Oup's une erreur est survenu :(");
        });
      }
    })
  }

  edit(category: Category[]){
    this.category = category;
    this.display = true;
  }

  saver(formData: any){
    if(!formData.isAddForm) {
      this.updateCategory(formData);
    } else {
      this.addCategory(formData);
    }
    this.display = false;
    this.category = [];
  }

  updateCategory(formData: any){
    console.log('update');
    let data = {
      name: formData.name,
      capacity: formData.capacity,
      row: formData.row,
      column: formData.column,
      
      updated_at: moment().format()
    };

    if (formData.name=='Rack 1'){
      data.row=1;
    }else if (formData.category=='Rack 2'){
      data.row=2;
    }else if (formData.category=='Rack 3'){
      data.row=3;
    }else{
      data.row=1;
    }
    

    this.afs.doc<any>(`categories/${formData.id}`).update(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("Oup's une erreur est survenu :(");
    });
  }

  addCategory(formData: any){
    console.log('add');
    let data = {
      id: this.afs.createId(),
      name: formData.name,
      capacity: formData.capacity,
      row: formData.row,
      column: formData.column,
      
      created_at: moment().format(),
      updated_at: moment().format()
    };

    if (formData.name=='Rack 1'){
      data.row=1;
    }else if (formData.name=='Rack 2'){
      data.row=2;
    }else if (formData.name=='Rack 3'){
      data.row=3;
    }else{
      data.row=1;
    }
    

    this.afs.collection("categories").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("Oup's une erreur est survenu :(");
    });
  }

}


//status: formData.status == 'enable' ? true : false,
//status: formData.status == 'enable' ? true : false,