<div class="col-md-12">
  
    <div class="card  cp">
      <div class="card-body">
  
        <div class="row">
          <div class="col-md-6 text-start">
            <p class="title text-black">Admins</p>
          </div>
          <div class="col-md-6 text-right">
        
            <button (click)="add()" label="Add New Admin" icon="pi pi-plus" pButton type="button" class="p-button-sm"></button>

          </div>
        </div>
  
        <p-table dataKey="id" stateKey="users" [value]="users" styleClass="p-datatable-striped" [rows]="10"
      [paginator]="true" #yp1 lang="fr">
  
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            Name 
          </th>
          <th class="text-right" pSortableColumn="created_at">Date Added
          </th>
          <th></th>
          
          
        </tr>
      </ng-template>
  
      <ng-template pTemplate="body" let-user>
        <tr>
          <td class="text-left"><span class="p-column-title">Name     :</span>{{ user.email }}</td>
          <td class="text-right"><span class="p-column-title">Date Added :</span>{{ user.created_at | date: "yyyy-MM-dd" }}</td>
          <td class="text-right">
            
            <button (click)="delete(user.id)" pButton type="button" icon="pi pi-trash" class="p-button-sm p-button-danger"></button>
          </td>
          
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" class="d-none">
        <tr>
          <td colspan="7">No users found.</td>
        </tr>
      </ng-template>
    </p-table>
  
    </div>     
    </div>
  </div>
  
<p-dialog *ngIf="display" [header]="user.name ? 'User: ' + user.name : 'Add new admin'"  [resizable]="true" [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="display" [style]="{width: '50vw'}">
    <app-admins [display]="display" [admin]="user" (call)="saver($event)"></app-admins>
</p-dialog>
  