<div class="col-md-12">

  <div class="card  cp">
    <div class="card-body">

      <div class="row">
       
        <div class="col-md-4 text-start">
          <p class="title text-black">Inventory</p>
        </div>  
        
        <div class="col-md-8 text-right">
          <button (click)="add()" label="Add" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>
          <!--<button (click)="sendMqttMessage()" label="Send" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>-->
          <!--<button (click)="scan()" label="Scan" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>-->
          <button (click)="scanBarcode()" label="Scan QR" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>
          <button (click)="addTest()" label="Test" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>

          <button (click)="details()" label="Back" pButton type="button" icon="pi pi-angle-left" class="p-button-sm"></button>
          <!--<button (click)="addTest2()" label="Test2" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>
          <button (click)="addTest3()" label="Test3" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>
          <button (click)="addTest4()" label="Test4" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>-->
          <!--<button (click)="sendSpecialMessage()" label="MQTT" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>-->
          
          <!--camera-->
          <div>
            <!-- Camera preview -->
            

          </div>
          <!--camera-->
          
        </div>
    
      </div>
    
      <p-table dataKey="id" stateKey="products" [value]="products" styleClass="p-datatable-striped" [rows]="8"
        [paginator]="true" #yp1 lang="fr">
        
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">Name</th>
            <th class="text-center" pSortableColumn="price">Price </th>
            <th class="text-center" pSortableColumn="expiry">Expiry </th>
            <th class="text-center" pSortableColumn="category">Rack ID</th>
            <th class="text-right" pSortableColumn="created_at">Displayed Date </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr>
            <td class="text-left"><span class="p-column-title">Name     :</span>{{ product.name }}</td>
            <td class="text-center"><span class="p-column-title">Price     :</span>{{ product.price | currency:'KRW' }}</td>
            <td class="text-right"><span class="p-column-title">Expiry     :</span>{{ product.expiry | date: 'yyyy-MM-dd, h:mm:ss a' }}</td>
            <td class="text-center"><span class="p-column-title">Showcase ID     :</span>{{ product.category }}</td>
            <td class="text-right"><span class="p-column-title">Displayed Date     :</span>{{ product.created_at | date: 'yyyy-MM-dd, h:mm:ss a' }}</td>
            <td class="text-right">
              <button (click)="edit(product)" pButton type="button" icon="pi pi-pencil" class="p-button-sm mr-2"></button>
              <button (click)="delete(product.id)" pButton type="button" icon="pi pi-trash" class="p-button-sm p-button-danger"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" class="d-none">
          <tr>
            <td colspan="7">No products found.</td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>

  

</div>

<p-dialog *ngIf="display" [header]="product.name ? 'Category: ' + product.name : 'Add new product'"  [resizable]="true" [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="display" [style]="{width: '50vw'}">
  
  <div>
    <!--upload-->
    <input type="file" accept="image/*" (change)="onFileSelected($event)" #fileInput hidden />
    <button (click)="fileInput.click()" label="Upload Picture" pButton type="button" class="p-button-sm"></button><br>
    
            

              <div *ngIf="previewImageUrl" class="preview-container">
                <button class="close-button" (click)="clearPreview()" pButton type="button" class="p-button-sm p-button-danger">X</button>
                <img [src]="previewImageUrl" alt="Preview" class="preview-image" />
              </div>
              
            
  </div>
  <app-product [display]="display" [product]="product" (call)="saver($event)"></app-product>
</p-dialog>

<p-dialog *ngIf="displayScan" [header]="'Scan new product'" [resizable]="true" [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="displayScan" [style]="{width: '50vw'}">
   <app-scan [display]="displayScan"></app-scan>
</p-dialog>

<p-dialog *ngIf="displayScanBarcode" [header]="'Scan new QR'" [resizable]="true" [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="displayScanBarcode" [style]="{width: '50vw'}">
  <form #barcodeForm="ngForm">
    <input #barcodeInput type="text" name="barcode" [(ngModel)]="scannedBarcode" placeholder="Scan a barcode" (change)="onBarcodeScanned()" autofocus><br><br>
    <div class="fixed-section">
      <div *ngFor="let barcode of scannedBarcodes">
        {{ barcode }}
      </div>
    </div><br><br>
    
    <button (click)="clear(); setAutofocus()" pButton type="button" label="Clear" class="p-button-sm p-button-danger"></button>
    <button (click)="saverBarcode(scannedBarcodes)" label="Save" pButton type="button" class="p-button-sm"></button>
  </form>
  
  
</p-dialog>

<!--
<form #barcodeForm="ngForm">
  <input type="text" name="barcode" [(ngModel)]="scannedBarcode" placeholder="Scan a barcode" (change)="onBarcodeScanned()">
  <button type="button" (click)="saveBarcode()">Save</button>
</form>

<div *ngFor="let barcode of scannedBarcodes">
  {{ barcode }}
</div>


<app-scan-barcode [display]="displayScanBarcode" [product]="product" (call)="saverBarcode($event)"></app-scan-barcode>
-->

 <!--
  <br><br>

             <div *ngIf="showCamera && !previewImageUrl" class="camera-preview">
               
               <video #videoElement width="100%" height="100%" autoplay></video>

               <br><br>

               <button (click)="capturePicture()" label="Capture" pButton type="button" class="p-button-sm"></button>
             </div>
 -->

 <!--<button class="close-button" (click)="stopCamera()" pButton type="button" class="p-button-sm p-button-danger">X</button>-->
 <!--<button (click)="ntakePicture()" label="Take Picture" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>-->