<div class="row">

  

  <div class="col-md-3">

    <!--<button (click)="registerUser('user@example.com', 'password123')" label="Sign up" pButton type="button" icon="pi pi-plus" class="p-button-sm"></button>-->

    <div class="card  cp" [routerLink]="['/admin/categories']">
      <div class="card-body">
        
        <p class="card-title text-black ">Total Racks</p>
        <p class="card-text text-black">{{ categories }}</p>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card  cp" [routerLink]="['/admin/products']">
      <div class="card-body">
        <p class="card-title text-black ">Products</p>
        <p class="card-text text-black">{{ products }}</p>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card  cp" [routerLink]="['/admin/sales']">
      <div class="card-body">
        <p class="card-title text-black ">Sales</p>
        <p class="card-text text-black">{{ sales }}</p>
      </div>
    </div>
  </div>
  
  <div class="col-md-3">
    <div class="card  cp" [routerLink]="['/admin/management']">
      <div class="card-body">
        <p class="card-title text-black ">Admin</p>
        <p class="card-text text-black">{{ admin }}</p>
      </div>
      
    </div>
  </div>

</div>

<app-chart-test></app-chart-test>
 





