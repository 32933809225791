<div class="content">
  <!-- Main content section -->
  <div class="main-section">
    <div class="card main-card">
      <div class="product-list-container">
        <span style="position: absolute; right: 0; bottom: 0; margin-right: 10px;">
          Temp & humidity: <b>{{ sensor[2] }}°C</b>, <b>{{ sensor[1] }}%</b><br><br>
        </span>
        <button (click)="details()" label="" pButton type="button" icon="pi pi-slack" class="p-button-sm"></button>
        <button (click)="logOut()" label="" pButton type="button" icon="pi pi-sign-out" class="p-button-sm"></button>

        <!-- DataView component for products grid layout -->
        <p-dataView [value]="allProducts" [rows]="4" layout="grid">
          <ng-template let-product pTemplate="gridItem">
            <span style="margin-right: 37px;">
              <div class="resizable-container" (click)="panier(product)">
              
                <div class="container">
                  <img [src]="product.imageUrl" alt="Avatar" class="image">
                  <div class="overlay" [ngClass]="{'blink': product.expiry < '2023-10-28'}">
                    <div class="text">
                      <div class="textH">
                        <b>{{ product.name }}</b>
                      </div>
                      <br>
                      {{ product.price | currency: 'KRW' }}
                      <br>
                      재고: {{ countName(product.name) }}
                    </div>
                  </div>
                  <br>
                  <button (click)="showPopup($event, product)" label="자세히" pButton type="button" class="details-button"></button>
                </div>
              
            </div>
            </span>
            
          </ng-template>
        </p-dataView>
      </div>
    </div>
  </div>

  <style>

.details-button {
  
  margin-top: 10px; /* Spacing from the content above */
  margin-left: 50px; /* Spacing from the content above */
  z-index: 2; /* Ensure button is above other elements */
}
    
.container {
  position: relative; /* Allows positioning of elements within */
  display: flex;
  flex-direction: column; /* Aligns children vertically */
  justify-content: space-between; /* Pushes the button to the bottom */
  height: 100%; /* Ensures the container takes full height of its parent */
}
.overlay {
  position: absolute;
  width: 235px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  opacity: 0.3;
  transition: opacity 0.5s ease;
  z-index: 1; /* Ensure overlay is on top of the image */
}
.resizable-container {
  width: 200px;
  width: 100%;
  max-width: 200px; /* Set maximum width for each product card */
  height: 340px; /* Adjust height as needed */
  margin: 0 auto; /* Center the card horizontally */
  padding: 10px; /* Add padding around the card */
  box-sizing: border-box; /* Include padding in the width calculation */
  position: relative; /* Ensure absolute positioning inside works */
}
.image {
  padding-top: 50px;
  height: 230px;
  width: 180px;
  object-fit: fill; /* Stretch the image to fill the entire container */
}

  
    
  </style>

  <!-- Cart section below the main content -->
  <div class="cart-section">
    <div class="card cart-card">
      <table class="table text-dark">
        <thead>
          <tr>
            <th class="text-ad">Item</th>
            <th class="text-ad"></th>
            <th class="text-ad">Location</th>
            <th class="text-ad"></th>
            <th class="text-ad">Quantity</th>
            <th class="text-ad"></th>
            <th class="text-ad">Price</th>
            <th class="text-ad"></th>
            
            <th class="text-ad">Edit</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="basket.length > 0; else emptyCart">
            <tr *ngFor="let x of basket">
              <td class="text-list">{{ x.name }}</td>
              <td class="text-list"></td>
              <td class="text-list">{{ x.row }}</td>
              <td class="text-list"></td>
              <td class="text-list">{{ x.quantity }}</td>
              <td class="text-list"></td>
              <td class="text-list">{{ x.price | currency: 'KRW' }}</td>
              <td class="text-list"></td>
              
              <td>
                <button class="btn btn-danger btn-lg px-2 py-2 mr-2" (click)="remove(x)">x</button>
              </td>
            </tr>
          </ng-container>
          <ng-template #emptyCart>
            <tr>
              <td colspan="4" class="text-center">No products in the cart.</td>
            </tr>
          </ng-template>
        </tbody>
      </table>

      <div class="container m-2">
        <div class="row align-content-center">
          <div class="col-md-2">
            <p class="mt-2 text-dark">{{ cartTotal | currency: 'KRW' }}</p>
          </div>
          <div class="col-md-8 text-right">
            <button label="Buy" pButton type="button" class="buy" (click)="checkout()"></button>
          </div>
        </div>
      </div>
    </div>
  
</div>
