<div class="row">

  <div class="col-md-3">
    <div class="card cp">
      <div class="card-body">
        <p class="card-title text-black">Cold Storage : 1</p>
        <p class="card-text text-black">{{ sensor[11] }}°C : {{ sensor[5] }}%</p>
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="card cp">
      <div class="card-body">
        <!--<h4 class="card-title ">Humidity</h4>-->
        <p class="card-title text-black">Cold Storage : 2</p>
        <p class="card-text text-black">{{ sensor[2] }}°C : {{ sensor[1] }}%</p>
      </div>
    </div>
  </div>

<div class="col-md-3">
  <div class="card cp">
    <div class="card-body">
      <p class="card-title text-black">Freezer : 1</p>
      <p class="card-text text-black">{{ sensor[9] }}°C : {{ sensor[3] }}%</p>     
    </div>
  </div>
</div>

<div class="col-md-3">
  <div class="card cp">
    <div class="card-body">
      <p class="card-title text-black">Freezer : 2</p>
      <p class="card-text text-black">{{ sensor[10] }}°C : {{ sensor[4] }}%</p>   
    </div>
  </div>
</div>

</div>

<div class="row">
  <div class="box">
    <div class="card cp" [routerLink]="['/admin/products']">
      <div class="card-body">
          <canvas baseChart width="475" height="400" font-size="17"
            [data]="{
              labels: showcaseArr,
              datasets: [
                { data: capacity, label: 'Capacity',
                  backgroundColor: ['rgba(255, 99, 132, 0.2)'],
                  borderColor: ['rgb(255, 99, 132)'],
                  borderWidth: 1

                 },
                { data: countArr, label: 'Current Stock',
                  backgroundColor: ['rgba(54, 162, 235, 0.2)'],
                  borderColor: ['rgb(54, 162, 235)'],
                  borderWidth: 1
                }
              ]
            }"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [type]="'bar'">
         </canvas>
      </div>
    </div>
  </div>
  
  <!--<div class="col-md-6">-->
  <div class="box">
    <div class="card cp" [routerLink]="['/admin/sales']">
      <div class="card-body">
          <canvas baseChart width="475" height="400"
            [data]="{
              labels: datesArr,
              datasets: [
                { data: totalSalesArr, label: 'Total Sales',
                  backgroundColor: ['rgba(255, 99, 132, 0.2)'],
                  borderColor: ['rgb(255, 99, 132)'],
                  borderWidth: 1
                }
              ]
            }"
            [options]="barChartOptions2"
            [plugins]="barChartPlugins2"
            [legend]="barChartLegend2"
            [type]="'bar'">
         </canvas>
      </div>
    </div>
  </div>

</div>


<!--

label: ('Total sales: '+ totalSales +' KRW'),

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/Chart.js/3.3.2/chart.js"></script>
<script>
  Chart.defaults.font.size= 20;
</script>


<div class="row">
  
</div>

-->
<!--
<div class="col-md-6">
  <div class="card cp">
    <div class="card-body">
      <canvas baseChart width="475" height="400"
        [type]="'line'"
        [data]="lineChartData"
        [options]="lineChartOptions"
        [legend]="lineChartLegend">
       </canvas>
    </div>
  </div>
</div>-->

<!-- <div class="row" style="display: block;">
  <div class="col-md-4">
    <canvas baseChart width="400" height="400"
      [type]="'line'"
      [data]="lineChartData"
      [options]="lineChartOptions"
      [legend]="lineChartLegend">
    </canvas>
  </div>
  <div class="col-md-4">
    <canvas baseChart width="400" height="400"
      [data]="barChartData"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [type]="'bar'">
   </canvas>
  </div>
</div> -->

<!--<p-table dataKey="id" stateKey="categories" [value]="categories" styleClass="p-datatable-striped" [rows]="10"
    [paginator]="true" #yp1 lang="fr">
    <ng-template pTemplate="body" let-category>
      <tr>
        <td>{{ categories[0].capacity }}</td>
      </tr>
     </ng-template>   
</p-table>-->

<!-- <td class="text-right">{{ sale.created_at | date: "dd/MM/yyyy" }}</td>
<div class="row">
  <div class="col-md-12">
    <div class="card cp">
      <div class="card-body">
        <canvas baseChart width="1000" height="400"
          [type]="'line'"
          [data]="lineChartData2"
          [options]="lineChart2Options"
          [legend]="lineChart2Legend">
         </canvas>
      </div>
    </div>
  </div>
</div>
-->