  <!-- <div class="text">
      
      
      <br>
      <br>
      <b>이름: {{ sharedObject.name }}</b>
      <br>
      <br>
      <b>가격: {{ sharedObject.price | currency:'KRW' }}</b>
      <br>
      <br>
      <b>만료: {{ sharedObject.expiry | date: 'yyyy-MM-dd,' }}{{ sharedObject.expiry | date: ' h:mm a'}}</b>
      <br>
      <br>
      <b> 안전 반사능률:  </b>
      <span class="badge bg-primary text-white">0.1 μSv/h</span>

      
    </div> -->


    <div class="text">
      <div class="nutrition-facts">
        <div class="fact-item">
          <b>Serving size:</b> <span>{{ sharedObject.serving }} ea</span>
        </div>
        <div class="fact-item">
          <b>Calories:</b> <span>{{ sharedObject.calories }} kcal</span>
        </div>
        <div class="fact-item">
          <b>Total Fat:</b> <span>{{ sharedObject.fat }} g</span>
        </div>
        <div class="fact-item">
          <b>Saturated Fat:</b> <span>{{ sharedObject.satFat }} g</span>
        </div>
        <div class="fact-item">
          <b>Carbohydrates:</b> <span>{{ sharedObject.carbohydrates }} g</span>
        </div>
        <div class="fact-item">
          <b>Fiber:</b> <span>{{ sharedObject.fiber }} g</span>
        </div>
        <div class="fact-item">
          <b>Sugar:</b> <span>{{ sharedObject.sugar }} g</span>
        </div>
        <div class="fact-item">
          <b>Protein:</b> <span>{{ sharedObject.protein }} g</span>
        </div>
        <div class="fact-item">
          <b>Vitamin C:</b> <span>{{ sharedObject.vc }} mg</span>
        </div>
        <div class="fact-item">
          <b>Vitamin A:</b> <span>{{ sharedObject.va }} IU</span>
        </div>
        <div class="fact-item">
          <b>Vitamin K:</b> <span>{{ sharedObject.vk }} μg</span>
        </div>
        <div class="fact-item">
          <b>Calcium:</b> <span>{{ sharedObject.calcium }} mg</span>
        </div>
        <div class="fact-item">
          <b>Iron:</b> <span>{{ sharedObject.iron }} mg</span>
        </div>
        <div class="fact-item">
          <b>Potassium:</b> <span>{{ sharedObject.potassium }} mg</span>
        </div>
        
      
      <br>
      
      <h2>Recommended Recipe</h2>
      
      <div class="recipe-pictures">
        <div *ngFor="let recipe of recommendedRecipes" class="recipe-picture" (click)="showRecipePopup(recipe)">
          <img [src]="recipe.pictureUrl" alt="Recipe Picture" />
          <span class="recipe-label">{{ recipe.name }}</span>
        </div>
      </div>
    </div>
    
    <!-- Popup for displaying recipe details -->
    <div *ngIf="selectedRecipe" class="recipe-popup">
      <button class="close-button" (click)="closePopup()">&times;</button>
      <h2>Recipe</h2>
      <ul>
        <li *ngFor="let ingredient of selectedRecipe.ingredients">
          {{ ingredient }}
        </li>
      </ul>
    </div>
    
    
    
    