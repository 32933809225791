import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldConfig, FormConfig } from 'ngx-nomad-form';
import { Group } from 'src/app/core/models/group';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  @Input() group: any;
  @Input() display: boolean = false;
  @Output() call = new EventEmitter<any>();

  isAddForm: boolean = false;

  //My fields
  fields: FieldConfig[] = [];

  formConfig: FormConfig = {
    name: 'groupForm',
    enctype: 'text/plain',
  };

  constructor() { }

  ngOnInit(){
    this.isAddForm = this.group?.name ? false : true;

    this.fields = [
      /*{
        type: 'input',
        label: 'Unique ID',
        inputType: 'number',
        name: 'uid',
        value: this.group?.uid,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'ID is required'
        }]
      },*/{
        type: 'input',
        label: 'Name',
        inputType: 'string',
        name: 'name',
        value: this.group?.name,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'name is required'
        }]
      }, {
        type: 'button',
        color: 'primary',
        col: 12,
        label: this.group.name ? 'Update' : 'Save'
      }
    ];
  }

  callBack(formData: any){
    console.log(formData);
    this.call.emit({
      'id' : this.group?.id,
      'isAddForm' : this.isAddForm,
      ...formData
    });
  }

}
