<div id="main-wrapper">

  <app-sidebar></app-sidebar>

<div class="content">
    <router-outlet></router-outlet>
</div>


</div>
