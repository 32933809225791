import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

import * as moment from 'moment';

import { FirebaseService, NotifyService } from 'src/app/core/services';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Discount } from 'src/app/core/models/Discount';
import Swal from 'sweetalert2';
import { FieldConfig } from 'ngx-nomad-form';
import { Validators } from '@angular/forms';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  discounts : Discount[] = [];
  promotions : any [] =[{
    'label': 'Spring',
    'value': 'Spring'
  }];
  display: boolean = false;
  displayPro: boolean = false;
  discount : any;
  promotion : any; 
  inventory: FieldConfig[]=[];

  @Output() call = new EventEmitter<any>();
  isAddForm: boolean = false;

  constructor(private firestore : FirebaseService, public notify : NotifyService, 
    private afs: AngularFirestore) { }

  ngOnInit() {
    this.fetch();

    this.inventory = [
      {
        type: 'input',
        label: 'Set limit',
        inputType: 'number',
        name: 'limit',
        value: this.discount?.expiry,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Current password is required'
        }]
      },
      {
        type: 'button',
        color: 'primary',
        col: 12,
        label: this.discount.name ? 'Update' : 'Save'
      }
    ];
  }

  callBack(formData: any){
    console.log(formData);
    this.call.emit({
      'id' : this.discount?.id,
      'isAddForm' : this.isAddForm,
      ...formData
    });
  }

  add(){
    this.discount = [];
    this.display= true;
  }

  addPro(){
    this.promotion = [];
    this.displayPro= true;
  }

  fetch() {
    this.afs.collection('discounts').valueChanges().subscribe((res : any) => {
      this.discounts = res;
      console.log(this.discounts);
    });
  }

  saver(formData: any){
    if(!formData.isAddForm) {
      this.updateDiscount(formData);
    } else {
      this.addDiscount(formData);
    }
    this.display = false;
    this.discount = [];
  }

  edit(discount: Discount[]){
    this.discount = discount;
    this.display = true;
  }

  refresh(){
    this.fetch();
  }

  delete(id: string){
    Swal.fire({
      title: 'Are you sure?',
      text: "Discount rule should not be deleted.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.firestore.deleteDocument('discounts', id).then(val => {
          Swal.fire(
            'Deleted!',
            'This discount rule has been deleted.',
            'success'
          );
          this.fetch();
        }, err => {
          this.notify.error2("Oup's une erreur est survenu :(");
        });
      }
    })
  }

  updateDiscount(formData: any){
    console.log('update');
    let data = {
      expiry: formData.expiry,
      percentage: formData.percentage,
      season: formData.season
    };

    this.afs.doc<any>(`discounts/${formData.id}`).update(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("Oup's une erreur est survenu :(");
    });
  }

  addDiscount(formData: any){
    console.log('add');
    let data = {
      id: this.afs.createId(),
      expiry: formData.expiry,
      percentage: formData.percentage,
      season: formData.season
    };

    this.afs.collection("discounts").doc(data.id).set(data).then(() => {
      this.fetch();
    }, err => {
      this.notify.error2("Oup's une erreur est survenu :(");
    });
  }

}
