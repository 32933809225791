import { Component, OnInit } from '@angular/core';
//import { NgxScannerQrcodeService, SelectedFiles } from 'ngx-scanner-qrcode';
import { ProductsComponent } from '../products.component';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})
export class ScanComponent implements OnInit {

  temp: any;

  public config: Object = {
    isAuto: false,
    text: { font: '0px' }, // Hiden { font: '0px' },
    frame: { lineWidth: 0 },
    medias: {
      audio: false,
      video: {
        facingMode: 'environment', // To require the rear camera https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
        width: { ideal: 350 },
        height: { ideal: 200 }
      }
    }
  };

  public onError(e: any): void {
    alert(e);
  }

  public handle(action: any, fn: string): void {
    action[fn]().subscribe(console.log, console.error);
  }

  constructor(private product: ProductsComponent) { 
    
  }

  ngOnInit(): void {
  }

  qr(value: any){
    
    //console.log(value._value)
    //this.product.addProduct({id: undefined, isAddForm: true, name: value.data._value, price: '1000', category: '19He5XlTo9nMzI13ASNE', group: "9rYQwdwUvkPpugFQ8qm3", row: "1", column: "1", imageUrl: "https://thumbs.dreamstime.com/z/tuna-fish-steak-12616350.jpg", expiry: "2022-11-11"})
    //console.log(JSON.parse(value.data._value));
    
    this.temp = JSON.parse(value.data._value);
    this.product.addQrProduct(this.temp);
    this.product.displayScan=false;

  }

}
