<div class="col-md-12">

  <div class="card  cp">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 text-start">
          <!--<h2 class="mt-2">Sales</h2>-->
          <p class="title text-black">Sales</p>
        </div>
        <div class="col-md-6 text-right">
          <button (click)="refresh()" pButton type="button" icon="pi pi-refresh" class="p-button-sm p-button-link"></button>
        </div>
      </div>
    
      <p-table dataKey="id" stateKey="sales" [value]="sales" styleClass="p-datatable-striped" [rows]="8"
        [paginator]="true" #yp1 lang="fr">
        
        <ng-template pTemplate="header">
          <tr>
            <th class="text-left" pSortableColumn="orderId">
              Order ID
            </th>
            <th class="text-center" pSortableColumn="carts">
              Cart 
            </th>
            <th class="text-center" pSortableColumn="amount">Total </th>
            
            <th class="text-center" pSortableColumn="status">Status </th>
            <th class="text-right" pSortableColumn="created_at">Transaction Date </th>
            
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-sale>
          <tr>
            <td class="text-left"><span class="p-column-title">Order ID:</span>{{ sale.orderId }}</td>
    
            <td class="text-center"><span class="p-column-title">Cart:</span>
              
              <div class="text-adjust">
                <p class="badge badge-light mr-1 font-weight-normal">{{ cartFilter(sale.carts) }}</p>
              </div>
              
              <!--<button (click)="sendMqttMessage(mqttSender(sale.carts))" label="Send" pButton type="button" class="p-button-sm"></button>-->
              <!--<button (click)="printFiltered()" label="Print" pButton type="button" class="p-button-sm"></button>-->
              
    
            </td>
            
            <td class="text-center"><span class="p-column-title">Total:</span>{{ sale.amount  | currency:'KRW' }}</td>
            
            <td class="text-center"><span class="p-column-title">Status:</span>
              <span class="badge bg-primary text-white" *ngIf="sale.status">Complete</span>
              <span class="badge bg-danger text-white" *ngIf="!sale.status">Pending</span>
            </td>
            <td class="text-right"><span class="p-column-title">Date:</span>{{ sale.created_at | date: 'yyyy-MM-dd, h:mm:ss a' }}</td>
            
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" class="d-none">
          <tr>
            <td colspan="7">No sales found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  

</div>



<!--
  <button (click)="cartSee()" label="" pButton type="button" icon="pi pi-shopping-cart" class="p-button-sm"></button>

          <p-dialog *ngIf="cartChk" [header]="'Cart'" [resizable]="true" [maximizable]="true" [modal]="true" header="Title" position="top" [(visible)]="cartChk" [style]="{width: '50vw'}">
              <p>{{ cartFilter(sale.carts) }}</p>
          </p-dialog>
-->
<!--<p class="badge badge-light mr-1 font-weight-normal">{{ cartFilter(sale.carts) }}</p>-->
<!--<p *ngFor="let item of carts(sale.carts)" class="badge badge-light mr-1 font-weight-normal">{{ item.name }}</p>-->
<!--<span *ngFor="let item of carts(sale.carts)" class="badge badge-light mr-1 font-weight-normal">{{ item.name }} x {{ item.quantity }}</span>-->
<!--
  
  <app-scan [display]="cartChk"></app-scan>
  <th pSortableColumn="carts">
          Cart <p-columnFilter type="text" field="carts" display="menu"></p-columnFilter>
        </th>
      <td class="text-right">{{ sale.created_at | date: "dd/MM/yyyy" }}</td>-->