<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-5">
      <div class="card">
        <div class="card-body">
          <div class="text-center">
            
            <p class='card-title'>Showcase Authentication</p>
          </div>
          <hr>
          <nf-form [formConfig]="formConfig" [fields]="fields" (callBack)="callBack($event)"></nf-form>
        </div>
      </div>
    </div>
  </div>
</div>
