import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';
import { Category, Product } from 'src/app/core/models';
import { FirebaseService, NotifyService } from 'src/app/core/services';
import Swal from 'sweetalert2';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Timestamp } from 'firebase/firestore';
import { AuthenticationService } from 'src/app/core/services';
import * as mqtt from 'mqtt/dist/mqtt';
import { Group } from 'src/app/core/models/group';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PopupComponent } from './popup/popup.component';
import { PopupService } from './popup/popup.service';
import { WebSocketService } from './websocket_service';




@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  providers: [DialogService],
  styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit {

  

  products : any;
  content: any = null;
  basket: any = [];
  backup : any = [];
  cartTotal: number =0;
  cartNumItems: number;
  discounts : any;
  expNum:number;
  perNum:number;
  tempMqtt:any;
  tempMqtt2:any;
  recentMqttData:any;
  showCartSection = false;
  allProducts: any=[];
  allProductsSameName: any=[];
  sendCheckoutValue=0;

  rack: any;
  sensor:any;
  filteredRack: any;
  mqttLoop:any =0;
 

  mqttServer = 'mqtt://broker.emqx.io';
  mqttPort = 1883;
  mqttTopic = 'bins';
  saleFilter: any = [];
  temp:any;
  
  groups : Group[] = [];
  count: any=0;
  countGroup : any = [];

  constructor(
    private firestore : FirebaseService,
    private notify : NotifyService,
    private afs: AngularFirestore,
    private db: AngularFireDatabase,
    public auth : AuthenticationService,
    public router : Router,
    private dialogService: DialogService,
    private popup: PopupComponent,
    private popupService: PopupService,
    private webSocketService: WebSocketService
  ) { }

  ngOnInit() {
      this.fetch();
      this.fetchAllProducts();
      this.fetchAllProductsForCountName();
      
      this.getTempHumid();
      this.fetchProductsTypes();
      this.refreshMqtt();
      
  }

  showCart(){
    this.showCartSection= true;
  }

  refreshMqtt(){
    const client = mqtt.connect(this.options);
      client.subscribe('confirmed',1); 
      client.on('message', (topic: string, message: Buffer) => {
      if (topic === 'confirmed') {
        this.tempMqtt = message.toString(); 
        if(this.tempMqtt == this.tempMqtt2){
          this.sendMqttMessage(this.recentMqttData);
          
          
        }else{
          
          if(this.mqttLoop >0){
            console.log('Received confirmation:', message.toString());
            this.tempMqtt2 = message.toString();
            
            
          }else{
            this.tempMqtt2 = message.toString();
          }

          this.mqttLoop = this.mqttLoop+1;
          console.log(this.mqttLoop)
          
        }
        
      }
    });
  }

  mapNumber(input: number): number | null {
    const numberMap: { [key: number]: number } = {
      41: 1, 42: 2, 43: 3, 44: 4,
      31: 5, 32: 6, 33: 7, 34: 8,
      21: 9, 22: 10, 23: 11, 24: 12,
      11: 13, 12: 14, 13: 15, 14: 16
    };
  
    return numberMap[input] || null;
  }

  checkout() {

    //this.mqttcheck = 0;
    console.log("Buy Pressed")

    if (this.basket.length > 0) {

      let orderValidity = 0;
      

      this.basket.forEach(order => {

        const { quantity, name } = order;

        let productsWithSameName = this.countName(name);
  
        if(quantity>productsWithSameName){
            Swal.fire("재고부족", "", "error");
            orderValidity=1;
            return ;
        }
      })

      if (orderValidity != 1){
        this.deleteProduct(this.basket);
        
       Swal.fire({
          title: 'Please wait...',
          allowOutsideClick: false, 
          showConfirmButton: false, 
          onBeforeOpen: () => {
            Swal.showLoading(); 
            setTimeout(() => {
              
              Swal.close();
            }, 60000); 
          }
        });

        this.saveOrder(this.basket);

        console.log(this.mapNumber(this.extractLocation(this.basket)[0]));
        console.log('extra step');
        console.log(this.sendCheckoutValue);

        this.sendCheckout(this.sendCheckoutValue);

        // deleted saveorder from here and moved to refreshmqtt to show only after mqtt success
        //this.sendMqttMessage(this.formatSaleFilter(this.extractLocation(this.basket),this.extractQuantity(this.basket)));
        //this.recentMqttData = this.formatSaleFilter(this.extractLocation(this.basket),this.extractQuantity(this.basket));
        
        console.log("LocationExtracts");
        console.log(this.extractLocation(this.basket)[0]);
        
        //this.webSocketService.sendMessageEach(parseInt(this.extractLocation(this.basket)[0], 10));
      }
      

    } else {
      Swal.fire("Cart is empty", "", "error");
    }

  }

  saveOrder(basket: any){
    //console.log('add');
    let data = {
      id: this.afs.createId(),
      orderId: Timestamp.now().seconds,
      carts: JSON.stringify(basket),
      amount: this.cartTotal,
      status: true,
      created_at: moment().format(),
      updated_at: moment().format()      
    };

    this.afs.collection("sales").doc(data.id).set(data).then(() => {
      Swal.fire("ORDER SUCCESS", "", "success").then(()=> location.reload());
    }, err => {
      this.notify.error2("Error :(");
    });
  }


  getTempHumid(){
    this.sensor=this.db.list('/test').valueChanges().subscribe((res:any) => { 
      this.sensor = res
      
    });
  }
  

  fetch(){
    this.afs.collection('categories').valueChanges().subscribe((res : any) => {
      this.rack = res;
      
        this.rack.forEach(async (element, key) => {
          this.rack[key].products = await this.findProducts(element.id);
          
          this.rackFilter() //needs important update here
        });

    });
  }

  

  countName(nameToCount: string): number {
    
    if(this.allProducts2!=null){
    
    return this.allProducts2.filter((product) => product.name === nameToCount).length;}
  }

  

  rackFilter(){

    this.filteredRack = [];
  
    for (let i = 0; i < this.rack.length; i++) {
      const uniqueProducts: any = {};

      // Empty rack[i].products Error CHECKING
      if (this.rack[i].products == null) {
        return;
      }
  
      for (let j = 0; j < this.rack[i].products.length; j++) {
        const product = this.rack[i].products[j];
        if (!uniqueProducts[product.group]) {
          uniqueProducts[product.group] = product;
        }
      }
  
      const uniqueProductsArray = Object.values(uniqueProducts);
  
      // Create a new rack with the filtered products
      const filteredRackItem = {
        ...this.rack[i],
        products: uniqueProductsArray
      };
  
      this.filteredRack.push(filteredRackItem);

      this.filteredRack.forEach(rack => {
        rack.products.sort((a, b) => {
          const rowComparison = a.row - b.row;
          if (rowComparison !== 0) {
            return rowComparison;
          }
          return a.column - b.column;
        });
      }); //this sort row and column for products in POS

    }

    /*for (let j=0; j<this.filteredRack.length;j++){
      for (let k=0; k<this.filteredRack[j].products.length; k++){
        this.allProducts.push(this.filteredRack[j].products[k]);
        
      }  
    }*/ // adding all products i guess
  
    //console.log(this.allProducts)
  }
  
  fetchAllProducts() {
    this.afs.collection('products').valueChanges().subscribe((res: any) => {
      this.allProducts = this.filterAndSortProducts(res);
    });
  }

  allProducts2: any;

  fetchAllProductsForCountName() {
    this.afs.collection('products').valueChanges().subscribe((res: any) => {
      this.allProducts2 = res;
    });
  }
  
  filterAndSortProducts(products: any[]): any[] {
    const uniqueProductsMap = new Map();
  
    products.forEach(product => {
      const key = `${product.group}-${product.row}-${product.column}`;
      if (!uniqueProductsMap.has(key)) {
        uniqueProductsMap.set(key, product);
      }
    });
  
    const uniqueProducts = Array.from(uniqueProductsMap.values());
  
    // Sort the uniqueProducts array by row and column
    return uniqueProducts.sort((a, b) => {
      if (a.row < b.row) {
        return -1;
      }
      if (a.row > b.row) {
        return 1;
      }
      // If rows are equal, sort by column
      if (a.row === b.row) {
        return a.column - b.column;
      }
      return 0;
    });
  }
  

  fetchDiscount() {
    this.afs.collection('discounts').valueChanges().subscribe((res : any) => {
      this.discounts = res;
      //console.log(this.discounts);
    });
   
  }

  setExp() {
    this.expNum = this.discounts[0].expiry;
    return this.expNum;
    
  }

  setPer() {
    this.perNum = this.discounts[0].percentage;
    return this.perNum;
    
  }
  
  async findProducts(category){
    return new Promise(resolve => {
      this.afs.collection('products', ref => ref.where('category', '==', category)).valueChanges().subscribe((res : any) => {
        resolve(res);
      });
    });
  }


  show(item){
    this.content = item.content;
  }

  showGroupStock(groupID :any){
    
      for(let k=0;k<this.groups.length;k++){
        if(groupID==this.groups[k].id){
          return this.groups[k].stock;
        }
      }
   
    
  }

  showGroupName(groupID :any){
    
    for(let k=0;k<this.groups.length;k++){
      if(groupID==this.groups[k].id){
        return this.groups[k].name;
      }
    }
 
  
}

details(){
  this.router.navigate(['geiger']);
}

  //return this.groups[1].stock;
    //return this.groups[1].stock;

  fetchProductsTypes() {

    this.afs.collection('groups').valueChanges().subscribe((res : any) => {
      this.groups = res;

      for(let i=0; i<this.groups.length;i++){
        if(this.groups[i].id=='lUm00KQUdhoaVs7kLDEW'){         
          this.groups.splice(i,1);
        }}

      this.afs.collection('products').valueChanges().subscribe((res : any) => {
        this.products = res;

        this.countGroup=[];
        for (let i = 0; i < this.groups.length; i++) {
          this.products.forEach( x=> this.count = this.count+ this.checkNum(x.group, this.groups[i].id)); 
          this.countGroup.push(this.count);
          this.count=0;
        }

        
        for (let i = 0; i < this.countGroup.length; i++) {
          this.groups[i].stock = this.countGroup[i];
        }
        
      });

    });
  }

  checkNum(x:any,y:any){
    if(x==y){
      return 1;
    }else{
      return 0;
    }
  }

  sendCheckout( x: Number): void {
    // Initialize the WebSocket connection
    const socket = new WebSocket('wss://superb-drum-selected.ngrok-free.app');
  
    // Open the WebSocket connection and send the message
    socket.onopen = () => {
      const message = {
        value: x
      };
  
      // Convert the message to a JSON string and send it
      socket.send(JSON.stringify(message));
      console.log('Message sent:', message);
    };
  
    // Handle incoming messages from the server
    socket.onmessage = (event) => {
      console.log('Received response:', event.data);
    };
  
    // Handle any errors that occur
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    // Handle connection closure
    socket.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
    };
  }
  

  panier(a) {
    const existingItem = this.basket.find(item => item.name === a.name);
  
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      a = { quantity: 1, ...a };
      this.basket.push(a);

      this.sendCheckoutValue = this.mapNumber(this.extractLocation(this.basket)[this.basket.length-1]);
      this.basket[this.basket.length-1].row = this.mapNumber(this.extractLocation(this.basket)[this.basket.length-1])
      console.log(this.basket[this.basket.length-1].row);
    }
  
    this.calculateTotal();
  }

  add(x) {
    
    if (this.basket.includes(x)) {
      this.basket[this.basket.indexOf(x)].quantity += 1;
    } else {
      this.basket.push(x);
    }
    this.calculateTotal();
  }

  reduce(x) {
    
    if (this.basket[this.basket.indexOf(x)].quantity === 1) {
      this.remove(x);
    } else {
      this.basket[this.basket.indexOf(x)].quantity = this.basket[this.basket.indexOf(x)].quantity - 1;
    }
    this.calculateTotal();
  }

  remove(x) {
    
    if (this.basket.includes(x)) {
      
      const index = this.basket.indexOf(x);
      if (index > -1) {
        
        this.basket[this.basket.indexOf(x)].quantity = 1;
        this.basket.splice(index, 1);
      }
    }
    this.calculateTotal();
  }

  calculateTotal() {
    let total = 0;
    let cartitems = 0;
    
    
    this.basket.forEach(function (x) {
      
      total += ((x.price) * x.quantity);
      cartitems += x.quantity;
    });
    this.cartTotal = total;
    this.cartNumItems = cartitems;
  }

  
  clearCart() {
    
    this.basket.forEach(function (x) {
      x.quantity = 1;
    });
    
    this.basket = [];
    this.calculateTotal();
  }

  // extractLocation(products: Product[]): string[] {
  //   return products.map(product => product.row.toString() + product.column.toString());
  // }
  extractLocation(products: { row: number, column: number }[]): number[] {
    return products.map(product => Number(product.row.toString() + product.column.toString()));
  }

  extractQuantity(products: Product[]): string[] {
    return products.map(product => product.quantity.toString());
  }

  formatSaleFilter(saleFilter: string[],quantityFilter: string[]): any {
    return { 
      "cell_count": saleFilter,
      "quantity": quantityFilter 
    }
  }

  

options: mqtt.IClientOptions = {
  protocol: 'wss',
  hostname: 'broker.emqx.io',
  port: 8084,
  path: '/mqtt',
  //clientId: 'treehouse', // Replace with your desired client ID
  //ca: this.rootCACertificate, // Provide the root CA certificate
  // Other options if needed
};
  
  sendMqttMessage(data:any) {

    //const client = mqtt.connect(this.mqttServer, { port: this.mqttPort });
    //const client = mqtt.connect('wss://broker.emqx.io:8083/mqtt');

    const client = mqtt.connect(this.options);
  
    client.on('connect', () => {
      console.log('Connected to MQTT broker');

      // Convert the message to JSON
      const messageJson = JSON.stringify(data);
  
      // Publish the JSON message to the topic
      client.publish(this.mqttTopic, messageJson);
  
      console.log('Message sent:', messageJson);
  
      // Disconnect from the MQTT broker
      client.end();
    });
  
    client.on('error', (error) => {
      console.error('Error:', error);
      //console.error('Reason:', error.message); 
    });
  }

  deleteProduct(orders: any[]) {

    orders.forEach(order => {

      const { quantity, name } = order;
     
     
      this.allProductsSameName=this.allProducts2.filter(product => product.name === name);

      console.log(this.allProductsSameName)

      for (let i = 0; i < quantity; i++) {
        let productToDelete = this.allProductsSameName[i];
        if (productToDelete) {
          this.firestore.deleteDocument('products', productToDelete.id);
        } else {
          console.warn(`Product with id and group not found`);
        }
        
      }
  
    });
  
    this.fetch();
  }

  ifSameGroup(group: string){

    let productsWithSameGroup = [];
    for (const rackItem of this.rack) {
      for (const product of rackItem.products) {
        if (product.group === group) {
          productsWithSameGroup.push(product);
        }
      }
     }

     return productsWithSameGroup;
  }
  

  logOut(){
    this.auth.signOut();
  }

  ref: DynamicDialogRef ;
  

  showPopup(event: Event,product: any): void {

    event.preventDefault();
    event.stopPropagation();

    console.log(product)

    this.popupService.setSharedObject(product);

    this.ref = this.dialogService.open(PopupComponent, {
      header: 'Nutrition Facts',
      width: '70%',
      data: {
        id: '51gF3'
    }
      
    });
  }

}



