import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldConfig, FormConfig } from 'ngx-nomad-form';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

  @Input() discount: any;
  @Input() display: boolean = false;
  @Output() call = new EventEmitter<any>();
  /*season: any[] = [
    {
      'label': 'Spring',
      'value': 1
    },{
      'label': 'Summer',
      'value': 2
    },{
      'label': 'Fall',
      'value': 3
    },{
      'label': 'Winter',
      'value': 4
    }
  ];*/

  isAddForm: boolean = false;

  //My fields
  fields: FieldConfig[] = [];

  formConfig: FormConfig = {
    name: 'discountForm',
    enctype: 'text/plain',
  };

  constructor() { }

  ngOnInit(){
    this.isAddForm = this.discount?.name ? false : true;

    this.fields = [
      {
        type: 'input',
        label: 'Current password',
        inputType: 'string',
        name: 'cpassword',
        value: this.discount?.expiry,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Current password is required'
        }]
      },{
        type: 'input',
        label: 'New Password',
        inputType: 'string',
        name: 'npassword',
        value: this.discount?.percentage,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'New Password is required'
        }]
      },
      {
        type: 'button',
        color: 'primary',
        col: 12,
        label: this.discount.name ? 'Update' : 'Save'
      }
    ];

    /*this.fields = [
      {
        type: 'input',
        label: 'Starting Expiry Time',
        inputType: 'number',
        name: 'expiry',
        value: this.discount?.expiry,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Expiry is required (12 to 5 hours)'
        }]
      },{
        type: 'input',
        label: 'Starting Percentage',
        inputType: 'number',
        name: 'percentage',
        value: this.discount?.percentage,
        col: 12,
        validations: [{
          name: 'required',
          validator: Validators.required,
          message: 'Percentage is required (0% to 50%)'
        }]
      },{
        type: 'select',
        label: 'Season',
        name: 'season',
        value: this.discount?.season,
        col: 12,
        options: [{
          'label': 'Spring',
          'value': 'Spring'
        },{
          'label': 'Summer',
          'value': 'Summer'
        },{
          'label': 'Fall',
          'value': 'Fall'
        },{
          'label': 'Winter',
          'value': 'Winter'
        }]
      },
      {
        type: 'button',
        color: 'primary',
        col: 12,
        label: this.discount.name ? 'Update' : 'Save'
      }
    ];*/
  }

  callBack(formData: any){
    console.log(formData);
    this.call.emit({
      'id' : this.discount?.id,
      'isAddForm' : this.isAddForm,
      ...formData
    });
  }
}
